import {createContext, useReducer} from 'react';

const initialState = {
  user: null,
  individual: null,
  membership: null,
  loading: true
};

const reducer = (state, action) => {
  switch (action.type) {
  case 'updateUser':
    return {...state, user: action.user, loading: false};
  case 'updateIndividual':
    return {...state, individual: action.individual};
  case 'updateMembership':
    return {...state, membership: action.membership};
  default:
    return state;
  }
};

export const ProfileContext = createContext(initialState);

export const ProfileStore = (props) => {
  const {children} = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ProfileContext.Provider value={
      [state, dispatch]
    }
    >
      {children}
    </ProfileContext.Provider>
  );
};
