import React, {useMemo} from 'react';
import {Box, IconButton, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import {FavoriteButton} from 'components/FavoriteButton/FavoriteButton';
import {useApi} from 'hooks/useApi';
import {entitiesService} from 'api/services/entities';
import {ReactComponent as DownloadIcon} from 'assets/icons/icon_download.svg';
import companyImageWhite from 'assets/icons/icon_company_white.svg';
import personImageWhite from 'assets/icons/icon_person_white.svg';
import vesselImageWhite from 'assets/icons/icon_vessel_white.svg';
import styles from './DetailsPanelHeader.module.css';
import {isVessel} from 'components/IUUStatus/IUUStatus';

export const icons = {
  person: {
    image: personImageWhite,
    bgColor: '#225B7B'
  },
  vessel: {
    image: vesselImageWhite,
    bgColor: '#122945'
  },
  company: {
    image: companyImageWhite,
    bgColor: '#F75151'
  }
};

export const DetailsPanelHeader = ({
  entity,
  onUpdateFavorite,
  onClose,
  viewNetworksOnClick,
  showNetworkButton
}) => {
  const theme = useTheme();
  const entityDownload = useApi({
    service: entitiesService.entityDownload,
    immediate: false
  });

  const icon = useMemo(() => (
    <Box
      className={styles.iconContainer}
      style={{backgroundColor: icons[entity.entity_type].bgColor}}
    >
      <img
        className={styles.icon}
        src={icons[entity.entity_type].image}
        alt={entity.entity_type}
      />
    </Box>
  ), [entity.entity_type]);

  const onDownload = () => {
    const triton_id = entity.triton_id;

    entityDownload.execute(triton_id).then((responseData) => {
      const url = responseData?.url;
      if (!url) {
        return;
      }

      const downloadLink = document.createElement('a');
      downloadLink.download = entity.label ? `${entity.label}.zip` : `${triton_id}.zip`;
      downloadLink.href = url;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      downloadLink.remove();
    });
  };

  return (
    <Box className={styles.container}>
      <IconButton
        onClick={onClose}
        className={styles.closeIcon}
      >
        <CloseIcon/>
      </IconButton>
      <Box
        display="flex"
        alignItems="center"
        className={styles.labelContainer}
      >
        {icon}
        <Box className={styles.labelBlock}>
          <Box display="flex" alignItems="center">
            <Typography
              color="secondary"
              className={styles.label}
              title={entity.label}
            >
              {entity.label}
            </Typography>
            <FavoriteButton
              isFavorite={entity.is_favorite}
              onUpdateFavorite={onUpdateFavorite}
              sx={
                {'& button': {marginBottom: '0.25rem'}}
              }
            />
          </Box>
          <Typography
            color="secondary"
            className={styles.entityType}
            style={
              {backgroundColor: theme.palette.gray.gray50}
            }
          >
            {entity.entity_type}
          </Typography>
        </Box>
      </Box>
      <Box className={styles.headerEndContent}>
        {
          !!showNetworkButton && (
            <Box
              className={styles.viewNetworkGraphBlock}
              display="flex"
              alignItems="center"
            >
              <Typography
                onClick={viewNetworksOnClick}
                className={styles.viewNetworkGraph}
                color="red.main"
              >
                View Network Graph
              </Typography>
              <LoadingButton
                loading={entityDownload.loading} className={styles.downloadButton}
                style={{borderColor: theme.palette.secondary.main}}
                onClick={onDownload}
              >
                <DownloadIcon
                  style={{fill: theme.palette.secondary.main}}
                  className={styles.downloadButtonIcon}
                />
              </LoadingButton>
            </Box>
          )
        }
        {isVessel(entity.entity_type) &&
        (
          <Typography
            className={styles.source}
            style={
              {color: theme.palette.gray.gray200}
            }
          >
            Additional information available from
            {' '}
            <a
              className={styles.sourceLink}
              href="https://www.spglobal.com/marketintelligence/en/mi/products/ship-and-port-data.html"
              target="_blank"
              rel="noreferrer"
            >
              S&P Global
            </a>
          </Typography>
        )
        }
      </Box>
    </Box>
  );
};
