import {useState, useRef, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useTheme} from '@mui/material/styles';
import Container from '@mui/material/Container';
import cn from 'classnames';
import {useApi} from 'hooks/useApi';
import {keydownListener} from 'utils/helpers';
import {entitiesService} from 'api/services/entities';
import {useEntityFilters} from 'hooks/useEntityFilters';
import {useTextInput} from 'hooks/useTextInput';
import {useSearchStore} from 'stores/hooks/useSearchStore';
import {QuickSearchInputs} from './components/QuickSearchInputs';
import {QuickSearchFilters} from './components/QuickSearchFilters/QuickSearchFilters';
import {ReactComponent as NetworkGraphBackground} from 'assets/backgrounds/background_net_graphic.svg';
import styles from './QuickSearch.module.css';

export const QuickSearch = () => {
  const {loading, data} = useApi({
    service: entitiesService.count,
    immediate: true
  });
  const {execute} = useSearchStore();
  const searchText = useTextInput();
  const filters = useEntityFilters();
  const [filtersOpen, setFiltersOpen] = useState(false);
  const menuRef = useRef(null);
  const navigate = useNavigate();
  const theme = useTheme();

  const handleClickSearch = () => {
    const urlSearchParams = execute({
      ...filters.values,
      textFilters: {
        ...filters.values.textFilters,
        name: searchText.input.value,
        sources: []
      }
    }, true);

    const searchParamsString = urlSearchParams.toString();
    if (searchParamsString === '') {
      navigate('/search');
    } else {
      navigate(`/search?${searchParamsString}`);
    }
  };

  useEffect(() => {
    return keydownListener('Enter', handleClickSearch);
  }, [filters]);

  const openFilters = () => {
    setFiltersOpen(true);
  };

  const closeFilters = () => {
    setFiltersOpen(false);
  };

  return (
    <form>
      <Card className={styles.card} ref={menuRef}>
        <Box
          className={styles.cardBox}
          sx={
            {background: `linear-gradient(0.25turn, ${theme.palette.secondary.main}, ${theme.palette.primary.main})`}
          }
        >
          <NetworkGraphBackground className={styles.networkGraphBackground}/>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Container maxWidth="md" className={styles.titleContainer}>
                <Typography className={styles.quickSearchTitle} variant="h4" color="white.main">
                  Search through
                  {' '}
                  <Typography
                    variant="span"
                    color="red.main"
                    className={
                      cn(
                        styles.quickSearchTitle,
                        styles.vesselsCount,
                        loading && styles.blurredText
                      )
                    }
                  >
                    {loading ? '00000' : data?.count.toLocaleString()}
                  </Typography>
                  {' '}
                  vessels, companies, and people available in Triton.
                </Typography>
                <Typography
                  className={styles.subQuickSearchTitle}
                  variant="h6"
                  color="white.main"
                >
                  Enter name or IMO Number of vessel, individual, or organization to begin a query.
                </Typography>
              </Container>
            </Grid>
            <Grid item xs={12}>
              <QuickSearchInputs
                searchText={searchText}
                onClickFilters={openFilters}
                onClickSearch={handleClickSearch}
              />
            </Grid>
          </Grid>
        </Box>
        <QuickSearchFilters
          open={filtersOpen}
          onClose={closeFilters}
          filters={filters}
        />
      </Card>
    </form>
  );
};
