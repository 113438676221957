import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import {GridOverlay} from '@mui/x-data-grid';

import NoResultsIcon from 'assets/icons/icon_empty.png';

export const EmptyOverlay = ({text}) => (
  <GridOverlay sx={{flexDirection: 'column'}}>
    <Stack spacing={5}>
      <Box
        sx={
          {
            display: 'flex',
            justifyContent: 'center'
          }
        }
      >
        <img src={NoResultsIcon} height="100" alt="no-data-icon"/>
      </Box>
      <Typography align="center">
        {text ? text : ''}
      </Typography>
    </Stack>
  </GridOverlay>
);
