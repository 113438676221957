import {Grid} from '@mui/material';
import cn from 'classnames';
import styles from './ProfileContainer.module.css';

export const ProfileContainer = ({children, className}) => (
  <Grid
    className={cn(styles.profileContainer, className)}
    container
    maxWidth="lg"
  >
    {children}
  </Grid>
);
