import {Grid, Stack} from '@mui/material';
import {LargeImageLoader} from 'components/LargeImageLoader/LargeImageLoader';
import styles from './AuthWrapper.module.css';

export const AuthWrapper = ({children, imageSrc, alt}) => (
  <Grid container className={styles.container}>
    <LargeImageLoader
      className={styles.backgroundContainer}
      imgClassName={styles.backgroundImage}
      imgSrc={imageSrc}
      alt={alt}
    />
    <Stack spacing={8} className={styles.authContainer}>
      {children}
    </Stack>
  </Grid>
);
