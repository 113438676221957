import {useMemo} from 'react';
import cn from 'classnames';
import {Button, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import styles from './SubmitBtn.module.css';

export const SubmitBtn = ({label, prefix, onClick, ...props}) => {
  const theme = useTheme();

  const labelComponent = useMemo(() => typeof label === 'string' ? (
    <Typography className={styles.submitBtnText}>
      {label}
    </Typography>
  ) : label, [label]);

  return (
    <Button
      {...props}
      variant="contained"
      type="submit"
      sx={
        {background: theme.palette.red.main}
      }
      onClick={onClick}
      className={cn(styles.submitBtn, props.className)}
    >
      {prefix}
      {labelComponent}
    </Button>
  );
};
