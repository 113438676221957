import {useEffect, useState} from 'react';
import {useUpdateService} from '../stores/hooks/useUpdateService';

export const useBroadcaster = (name) => {
  const {updateService} = useUpdateService();
  const [broadcaster, setBroadcaster] = useState();
  const [indexSubscriber, setIndexSubscriber] = useState();

  useEffect(() => {
    const [indexSubscriber, destroySearchBroadcaster] = updateService.registerBroadcaster(name);

    const broadcaster = updateService.broadcasters[name];
    setIndexSubscriber(indexSubscriber);
    setBroadcaster(broadcaster);

    return destroySearchBroadcaster;
  }, [name]);

  return [indexSubscriber, broadcaster];
};
