import {useEffect, useMemo, useState} from 'react';
import cn from 'classnames';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useTheme} from '@mui/material/styles';
import styled from '@emotion/styled';
import {ReactComponent as LockIcon} from 'assets/icons/icon_lock.svg';
import {useSearchStore} from 'stores/hooks/useSearchStore';
import styles from './FilterCard.module.css';

const StyledBox = styled(Box)(
  {},
  ({theme, active, disabled}) => {
    const styles = {
      backgroundColor: theme.palette.gray.gray50,
      color: theme.palette.gray.gray900,
      border: '2px solid transparent'
    };

    if (disabled) {
      styles.color = theme.palette.gray.gray100;
    } else if (active) {
      styles.color = theme.palette.secondary.main;
      styles.border = `2px solid ${theme.palette.secondary.main}`;
    }

    return styles;
  }
);

export const FilterCard = ({
  label,
  iconComponent,
  activeFiltersCount,
  active,
  onClick,
  isDisabled,
  reset
}) => {
  const {searchFilters, updateFilter} = useSearchStore();
  const [disabled, setDisabled] = useState(isDisabled(searchFilters));
  const theme = useTheme();

  useEffect(() => {
    setDisabled(isDisabled(searchFilters));
  }, [searchFilters]);

  const clear = (e) => {
    e.stopPropagation();
    reset(updateFilter, searchFilters);
  };

  const infoBadgeContent = useMemo(() => {
    if (disabled) {
      return <LockIcon/>;
    } else {
      return activeFiltersCount ? (
        <Typography
          className={styles.filtersCount}
          style={{color: theme.palette.white.main}}
        >
          {activeFiltersCount}
        </Typography>
      ) : null;
    }
  }, [disabled, activeFiltersCount]);

  const Icon = useMemo(() => iconComponent, [iconComponent]);

  const handleClick = () => {
    if (isDisabled(searchFilters)) {
      return;
    }

    onClick && onClick();
  };

  return (
    <StyledBox
      onClick={handleClick}
      className={cn(styles.card, disabled && styles.disabledCard)}
      active={active ? 1 : 0}
      disabled={disabled ? 1 : 0}
    >
      <Icon className={styles.image}/>
      <Typography className={styles.label}>{label}</Typography>
      {
        infoBadgeContent !== null && (
          <Box onClick={clear} className={styles.infoBadge}>
            {infoBadgeContent}
          </Box>
        )
      }
    </StyledBox>
  );
};
