import Box from '@mui/material/Box';

import {ViewNetworkButton} from './components/ViewNetworkButton';
import {ViewNetworkLink} from './components/ViewNetworkLink';

export const ViewNetwork = ({componentType, entityType, tritonId, handleClick}) => (
  <Box>
    {
      componentType === 'link' &&
        <ViewNetworkLink onClick={handleClick}/>
    }
    {
      componentType === 'button' && (
        <ViewNetworkButton
          entityType={entityType}
          tritonId={tritonId}
          handleClick={handleClick}
        />
      )
    }
  </Box>
);
