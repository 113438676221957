import React, {useMemo} from 'react';
import {useTheme} from '@mui/material/styles';
import {Typography} from '@mui/material';
import {getNode} from '../ConnectionsUtils';
import {NoMaxWidthTooltip} from 'components/NoMaxWidthTooltip/NoMaxWidthTooltip';
import {IUUStatus as IUUStatusComponent} from 'components/IUUStatus/IUUStatus';
import {ReactComponent as WarningIcon} from 'assets/icons/icon_warning.svg';
import styles from '../Connections.module.css';
import iuuStatusStyles from './IUUStatus.module.css';

export const IUUStatus = ({connection, edges, nodes}) => {
  const theme = useTheme();

  const entityInConnectionWithListedEntity = useMemo(() => {
    const asTarget = edges.filter((edge) => edge.target === connection.triton_id);
    const asSource = edges.filter((edge) => edge.source === connection.triton_id);

    const targets = asSource.map((edge) => edge.target);
    const sources = asTarget.map((edge) => edge.source);

    const listedTargetNode = targets.find((targetTritonId) => {
      const node = getNode(nodes, targetTritonId);
      if (node?.entity?.iuu_status === 'listed') {
        return node;
      }
    });

    const listedSourceNode = sources.find((sourceTritonId) => {
      const node = getNode(nodes, sourceTritonId);
      if (node?.entity?.iuu_status === 'listed') {
        return node;
      }
    });

    const result = listedTargetNode || listedSourceNode;
    return !!result;
  }, [edges, connection]);

  return entityInConnectionWithListedEntity
    ? (
      <NoMaxWidthTooltip
        arrow
        className={styles.relationTooltip}
        backgroundColor={theme.palette.secondary.main}
        title={
          <Typography className={styles.relationNode}>
            This entity is in a network with IUU-listed vessels
          </Typography>
        }
        placement="top"
        enterDelay={500}
      >
        <WarningIcon className={iuuStatusStyles.warningIcon} />
      </NoMaxWidthTooltip>
    )
    : (
      <IUUStatusComponent
        iuuStatus={connection.iuuStatus}
        entity_type={connection.scheme}
        className={styles.connectionsTableRowText}
        style={
          {color: theme.palette.gray.gray900}
        }
        empty={false}
      />
    );
};
