import {useEffect, useState} from 'react';

export const useSubscriber = (broadcaster, prefix) => {
  const [subscriber, setSubscriber] = useState();

  useEffect(() => {
    if (!broadcaster) {
      return;
    }

    const [subscriber, destroySubscriber] = broadcaster.createSubscriber(prefix);
    setSubscriber(subscriber);

    return destroySubscriber;
  }, [broadcaster]);

  return subscriber;
};
