import {useMemo} from 'react';
import {
  Avatar,
  Box,
  Typography
} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {ReactComponent as AccountCircleOutlinedIcon} from 'assets/icons/icon_profile.svg';
import {useProfileStore} from 'stores/hooks/useProfileStore';
import styles from './AccountHeader.module.css';

export const AccountHeader = () => {
  const [profile] = useProfileStore();
  const theme = useTheme();

  const userName = useMemo(() => {
    const firstName = profile?.user?.first_name;
    const lastName = profile?.user?.last_name;

    return [firstName, lastName].filter(Boolean).join(' ');
  }, [profile.user]);

  const roleInfo = useMemo(() => {
    const title = profile?.user?.title;
    const organization = profile?.user?.organization || 'C4ADS';

    return [title, organization].filter(Boolean).join(' | ');
  }, [profile.user]);

  return (
    <Box
      className={styles.header}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      style={
        {boxShadow: `4px 4px 8px ${theme.palette.gray.alpha10}`}
      }
    >
      <Box
        display="flex"
        alignItems="center"
        className={styles.userContainer}
      >
        <Avatar className={styles.avatar}>
          {
            profile?.user?.avatar
              ? <img className={styles.avatarImage} src={profile?.user?.avatar} alt="Account avatar"/>
              : (
                <AccountCircleOutlinedIcon
                  variant="account"
                  className={styles.avatarImage}
                  style={
                    {fill: theme.palette.secondary.main}
                  }
                />
              )
          }
        </Avatar>
        <Box>
          <Typography
            className={styles.username}
            variant="h5"
            color="secondary"
          >
            {userName}
          </Typography>
          <Typography
            className={styles.role}
            variant="subtitle2"
            style={
              {color: theme.palette.secondary.secondary200}
            }
          >
            {roleInfo}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
