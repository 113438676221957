import {forwardRef} from 'react';
import {Box, TextField, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import cn from 'classnames';
import styles from './CustomInput.module.css';

// eslint-disable-next-line react/display-name
export const CustomInput = forwardRef(({
  className,
  inputClassName,
  label,
  placeholder,
  InputProps,
  inputProps,
  sx,
  variant = 'outlined',
  onChange,
  value,
  disabled,
  required,
  type,
  InputLabelProps,
  autoComplete
}, ref) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="column"
      className={cn(styles.box, disabled && styles.boxDisabled, className)}
    >
      <Typography
        className={styles.label}
        style={
          {
            color: disabled
              ? theme.palette.black.alpha30
              : theme.palette.secondary.secondary200
          }
        }
      >
        {label}
      </Typography>
      <TextField
        ref={ref}
        type={type}
        required={required}
        disabled={disabled}
        value={value}
        placeholder={placeholder}
        InputProps={InputProps}
        sx={sx}
        variant={variant}
        onChange={onChange}
        className={
          cn(
            styles.textField,
            disabled && styles.textFieldDisabled,
            inputClassName
          )
        }
        {...inputProps}
        {...InputLabelProps}
        autoComplete={autoComplete}
      />
    </Box>
  );
});
