import {AuthWrapper} from 'components/AuthWrapper/AuthWrapper';
import {ResetForm} from './components/ResetForm';
import LoginBackground from 'assets/backgrounds/background_login.jpeg';
import {ReactComponent as LoginTritonLogo} from 'assets/icons/icon_login_triton_logo.svg';
import styles from './ResetPage.module.css';

export const ResetPage = () => (
  <AuthWrapper alt="login background" imageSrc={LoginBackground} >
    <LoginTritonLogo className={styles.resetPasswordLogo}/>
    <ResetForm />
  </AuthWrapper>
);
