import {useRef} from 'react';

export const useImperativeRef = (
  initialValue,
  {
    beforeCall,
    afterCall
  },
  functionsToListen
) => {
  const ref = useRef(initialValue);

  const createProxyForFunction = (functionLink) => new Proxy(functionLink, {
    apply: async (target, thisArg, argArray) => {
      const currentTarget = target.bind(thisArg);
      beforeCall();
      const result = await currentTarget(...argArray);
      afterCall();
      return result;
    }
  });

  const processNewValue = (value) => {
    ref.current = value;
    if (!value) {
      return;
    }

    functionsToListen.forEach((key) => {
      const functionLink = value[key];

      if (!functionLink) {
        return;
      }

      value[key] = createProxyForFunction(functionLink);
    });
  };

  return [ref, processNewValue];
};
