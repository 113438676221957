import {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Grid, Box, Button} from '@mui/material';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useImperativeRef} from 'hooks/useImperativeRef';
import {useAdminStore} from 'stores/hooks/useAdminStore';
import {Loader} from 'components/Loader/Loader';
import {CustomInput} from 'components/CustomInput/CustomInput';
import {ErrorMessage} from 'components/Messages/ErrorMessage';
import {ConfirmOrganizationDetails} from '../ConfirmOrganizationDetails/ConfirmOrganizationDetails';
import {SubmitBtn} from '../../components/SubmitBtn/SubmitBtn';
import {Footer} from '../../components/Footer/Footer';
import addOrganizationStyles from './AddOrganization.module.css';
import styles from '../../Admin.module.css';

const schema = yup.object({name: yup.string().required()}).required();

export const SingleCreation = ({
  onOrganizations,
  onClose,
  onShowModal,
  onHideModal
}) => {
  const {organizations: storedOrganizations} = useAdminStore();
  const organizations = storedOrganizations.data || [];
  const [confirmOrganizationDetailsRef, setConfirmOrganizationDetailsRef] =
    useImperativeRef(
      null,
      {
        beforeCall: onHideModal,
        afterCall: onShowModal
      },
      ['open']
    );
  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    formState: {errors},
    watch,
    setError
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {name: ''}
  });
  const currentName = watch('name');

  const nameAlreadyRegistered = (name) => {
    if (errors.name?.type !== undefined && errors.name?.type !== 'custom') {
      return errors.name;
    }
    if (!name.length) {
      return;
    }

    const organization = organizations.find(
      (organization) => organization.name === name
    );

    const errorMessage = organization
      ? {
        type: 'custom',
        message: `Organization with name: '${name}' already registered.`
      }
      : undefined;

    setError('name', errorMessage);

    return errorMessage;
  };

  useEffect(() => {
    nameAlreadyRegistered(currentName);
  }, [currentName]);

  const onSubmit = async (organization) => {
    const nameCheckResult = nameAlreadyRegistered(organization.name);
    if (nameCheckResult) {
      return;
    }
    setLoading(true);
    const result = await confirmOrganizationDetailsRef.current.open([{...organization}]);

    setLoading(false);
    if (result?.confirmed) {
      const organizationsData = storedOrganizations.data || [];
      storedOrganizations.update(
        organizationsData.concat(
          result.organizations.map((organization) => ({
            ...organization,
            members_count: 0
          }))
        )
      );

      onOrganizations(result.organizations);
    }
  };

  return (
    <>
      <Loader absolute={true} loading={loading} />
      <Box className={styles.singleCreation}>
        <ConfirmOrganizationDetails ref={setConfirmOrganizationDetailsRef} />
        <Grid container>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Controller
                control={control}
                rules={{required: true}}
                render={({field: {...props}}) => (
                  <CustomInput
                    required={true}
                    placeholder="Organization Name"
                    {...props}
                  />
                )}
                name="name"
              />
              {!!errors.name && <ErrorMessage message={errors.name.message} />}
            </Grid>
          </Grid>
        </Grid>
        <Footer display="flex" justifyContent="flex-end">
          <Button
            className={addOrganizationStyles.cancelButton}
            variant="text"
            onClick={onClose}
          >
            Cancel
          </Button>
          <SubmitBtn
            onClick={async () => {
              await handleSubmit(onSubmit)();
              nameAlreadyRegistered(currentName);
            }}
            label="Create Organization"
          />
        </Footer>
      </Box>
    </>
  );
};
