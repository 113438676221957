import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router';
import {useSearchParams} from 'react-router-dom';
import {
  Box,
  Drawer
} from '@mui/material';
import {DetailsPanelWrapper} from 'stores/contexts/DetailsPanelContext';
import {useDetailsPanelStore} from 'stores/hooks/useDetailsPanelStore';
import {entitiesService} from 'api/services/entities';
import {useApi} from 'hooks/useApi';
import {useSubscriber} from 'hooks/useSubscriber';
import {
  COMPANY_DETAIL_FIELDS,
  PERSON_DETAIL_FIELDS,
  VESSEL_DETAIL_FIELDS
} from 'constants/entities';
import {DetailsPanelHeader} from './DetailsPanelHeader/DetailsPanelHeader';
import {DetailsPanelBody} from './DetailsPanelBody/DetailsPanelBody';
import {Loader} from '../Loader/Loader';
import styles from './DetailsPanel.module.css';

export const DetailsPanel = (props) => {
  return (
    <DetailsPanelWrapper>
      <DetailsPanelContent
        {...props}
      />
    </DetailsPanelWrapper>
  );
};

const DetailsPanelContent = ({
  data,
  open,
  onClose: onCloseCallback,
  onCloseAfterNetworksOnClick,
  viewNetworksHandleClick,
  showNetworkButton,
  broadcaster
}) => {
  const {setMainNode} = useDetailsPanelStore();
  const [entityFields, setEntityFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [panelOpen, setPanelOpen] = useState(open);
  const [detailsData, setDetailsData] = useState();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const searchSubscriber = useSubscriber(broadcaster);

  const favorite = useApi({
    service: entitiesService.favorite,
    immediate: false
  });
  const unfavorite = useApi({
    service: entitiesService.unfavorite,
    immediate: false
  });
  const details = useApi({
    service: entitiesService.details,
    immediate: false
  });

  useEffect(() => {
    if (!data) {
      return;
    }
    setDetailsData(data);
  }, [data]);

  useEffect(() => {
    if (!details.data) {
      return;
    }

    setDetailsData({...details.data});
  }, [details.loading]);

  useEffect(() => {
    if (searchSubscriber) {
      open ? searchSubscriber.startListen() : searchSubscriber.stopListen();
    }
    setPanelOpen(open);
  }, [open, searchSubscriber]);

  useEffect(() => {
    if (!searchSubscriber) {
      return;
    }

    return searchSubscriber.on('data', (data) => {
      /*
        type: 'isFavorite', isFavorite: boolean, tritonId: string
       */
      if (data.type === 'isFavorite') {
        setDetailsData((prevDetailsData) => ({
          ...prevDetailsData,
          is_favorite: data.isFavorite
        }));
      }
    });
  }, [searchSubscriber, setDetailsData]);

  useEffect(() => {
    if (!detailsData) {
      setEntityFields([]);
    }
  }, [detailsData]);

  useEffect(() => {
    if (detailsData) {
      const entityType = detailsData.entity_type;

      if (entityType === 'company') {
        setEntityFields(COMPANY_DETAIL_FIELDS);
      } else if (entityType === 'person') {
        setEntityFields(PERSON_DETAIL_FIELDS);
      } else if (entityType === 'vessel') {
        setEntityFields(VESSEL_DETAIL_FIELDS);
      }

      setPanelOpen(true);
    }
  }, [detailsData]);

  const onClose = () => {
    setMainNode(null);
    onCloseCallback && onCloseCallback();
  };

  const handleUpdateFavorite = async (is_favorite) => {
    const payload = {triton_id: detailsData.triton_id};
    setLoading(true);

    const result = is_favorite ? await favorite.execute(payload) : await unfavorite.execute(payload);

    setLoading(false);
    if (result instanceof Error) {
      return;
    }

    if (broadcaster) {
      broadcaster.sendData({
        type: 'isFavorite',
        isFavorite: is_favorite,
        tritonId: detailsData.triton_id
      });
    }
    setDetailsData({...detailsData, is_favorite});
  };

  const viewNetworksOnClick = () => {
    if (viewNetworksHandleClick) {
      viewNetworksHandleClick(detailsData.triton_id);
    } else {
      if (searchParams.get('triton_id') !== detailsData.triton_id) {
        navigate(`/networks?triton_id=${detailsData.triton_id}`);
      } else {
        onClose();
        return;
      }
    }

    if (onCloseAfterNetworksOnClick) {
      onClose();
    }
  };

  const onMemberId = async (triton_id) => {
    setLoading(true);
    await details.execute(triton_id);
    setLoading(false);
  };

  return (
    <Box>
      <Drawer
        anchor="right"
        open={panelOpen}
        onClose={onClose}
        className={styles.drawer}
      >
        <Loader loading={loading} absolute={true}/>
        {
          !!detailsData && (
            <Box className={styles.drawerContent}>
              <DetailsPanelHeader
                showNetworkButton={showNetworkButton}
                entity={detailsData}
                onUpdateFavorite={handleUpdateFavorite}
                viewNetworksOnClick={viewNetworksOnClick}
                onClose={onClose}
              />
              <DetailsPanelBody
                onMemberId={onMemberId}
                fields={entityFields}
                data={!!detailsData && {...detailsData, ...detailsData.data}}
              />
            </Box>
          )
        }
      </Drawer>
    </Box>
  );
};
