import {useState} from 'react';
import {
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  PaginationItem,
  Popover,
  Pagination as AntdPagination,
  Typography
} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {ReactComponent as BackIcon} from 'assets/icons/icon_pagination_back.svg';
import {ReactComponent as ForwardIcon} from 'assets/icons/icon_pagination_forward.svg';
import {ReactComponent as StartIcon} from 'assets/icons/icon_pagination_start.svg';
import {ReactComponent as EndIcon} from 'assets/icons/icon_pagination_end.svg';
import styles from './SearchResultsTable.module.css';

const pagesCountOptions = [
  5,
  10,
  20,
  50,
  100
];

export const Pagination = ({pagination, onPageSize, onPage}) => {
  // pagination -> {page, count, pageSize}
  const theme = useTheme();
  const [paginatorAnchorEl, setPaginatorAnchorEl] = useState(null);

  const getShowingItemsText = () => {
    const pageSize = pagination.pageSize;
    const page = pagination.page;

    const start = page * pageSize;
    const end = start + pageSize;

    return `Showing ${start + 1}-${end} of ${pagination.count}`;
  };

  const getItemsPerPageText = () => (
    <>
      Show
      <ArrowDropDownIcon className={styles.paginationPageSizeIcon}/>
      {pagination.pageSize}
      {' '}
      results
    </>
  );

  const handleClickPaginator = (event) => {
    setPaginatorAnchorEl(event.currentTarget);
  };

  const handleClosePaginator = () => {
    setPaginatorAnchorEl(null);
  };

  const hideControls = pagination.count < pagination.pageSize;
  const countPages = () => {
    let count = Math.floor(pagination.count / pagination.pageSize);
    if (pagination.count % pagination.pageSize !== 0) {
      count += 1;
    }

    return count;
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      className={styles.pagination}
      style={
        {color: theme.palette.gray.gray250}
      }
    >
      <Typography className={styles.paginationTotal}>
        {getShowingItemsText()}
      </Typography>
      <AntdPagination
        shape="rounded"
        count={countPages()}
        page={pagination.page + 1}
        onChange={
          (e, page) => {
            onPage(page);
          }
        }
        showFirstButton
        showLastButton
        renderItem={
          (item) => (
            <PaginationItem
              slots={
                {
                  previous: () => hideControls ? null : <BackIcon/>,
                  next: () => hideControls ? null : <ForwardIcon/>,
                  first: () => hideControls ? null : <StartIcon/>,
                  last: () => hideControls ? null : <EndIcon/>
                }
              }
              {...item}
            />
          )
        }
      />
      <Popover
        open={Boolean(paginatorAnchorEl)}
        anchorEl={paginatorAnchorEl}
        onClose={handleClosePaginator}
        anchorOrigin={
          {
            vertical: 'bottom',
            horizontal: 'right'
          }
        }
        transformOrigin={
          {
            vertical: 'top',
            horizontal: 'right'
          }
        }
      >
        <List>
          {
            pagesCountOptions.map((pageSize) => (
              <ListItem
                key={pageSize}
                disablePadding
                onClick={
                  () => {
                    onPageSize(pageSize);
                  }
                }
              >
                <ListItemButton>
                  <ListItemText primary={pageSize}/>
                </ListItemButton>
              </ListItem>
            ))
          }
        </List>
      </Popover>
      <Typography
        className={styles.paginationPageSize}
        onClick={handleClickPaginator}
      >
        {getItemsPerPageText()}
      </Typography>
    </Grid>
  );
};
