import {createTheme} from '@mui/material/styles';

import Inter from '../assets/fonts/inter/Inter-Regular.ttf';

export const colors = {
  primary: {
    main: '#225B7B',
    light: 'rgba(34, 91, 123, 0.1)'
  },
  secondary: {
    main: '#122945',
    secondary200: '#536478',
    alpha10: 'rgba(18, 41, 69, 0.1)'
  },
  tertiary: {main: '#F1F3F6'},
  error: {main: '#E2574C'},
  warning: {main: '#ffa726'},
  info: {main: '#29b6f6'},
  success: {main: '#05A07E'},
  submitted: {
    main: '#1F38A8',
    background: '#EDF4FF'
  },
  underReview: {
    main: '#88602F',
    background: '#FFF4E8'
  },
  inProgress: {
    main: '#346C8A',
    background: '#E6F9FF'
  },
  complete: {
    main: '#017A47',
    background: '#EDFDF3'
  },
  canceled: {
    main: '#F75151',
    background: 'rgba(247, 81, 81, 0.14)'
  },
  black: {
    main: '#000000',
    alpha10: 'rgba(0, 0, 0, 0.1)',
    alpha30: 'rgba(0, 0, 0, 0.4)'
  },
  gray: {
    main: '#12294566',
    gray50: '#F0F1F3',
    gray100: '#CED3D9',
    gray200: '#B8BFC7',
    gray250: '#A7B0BB',
    gray275: '#A8B1BB',
    gray300: '#9BA5B1',
    gray900: '#536378',
    contrastText: '#FFFFFF',
    alpha6: 'rgba(18, 41, 69, 0.06)',
    alpha10: 'rgba(18, 41, 69, 0.1)',
    alpha30: 'rgba(18, 41, 69, 0.3)',
    alpha60: 'rgba(18, 41, 69, 0.6)'
  },
  grey: {
    main: '#12294566',
    contrastText: '#FFFFFF',
    grey200: '#C4C4C4',
    alpha6: 'rgba(18, 41, 69, 0.06)',
    alpha10: 'rgba(18, 41, 69, 0.1)',
    alpha30: 'rgba(18, 41, 69, 0.3)',
    alpha60: 'rgba(18, 41, 69, 0.6)'
  },
  steel: {
    main: '#90A6B9',
    alpha80: 'rgba(144, 166, 185, 0.8)'
  },
  white: {
    main: '#FFFFFF',
    off: '#FAFAFA',
    alpha60: 'rgba(255, 255, 255, 0.6)'
  },
  red: {
    main: '#F75151',
    alpha10: 'rgba(247, 81, 81, 0.1)',
    red800: '#F75151'
  },
  yellow: {main: '#FFC107'},
  green: {green50: '#CDE3D9'}
};

export const theme = createTheme({
  palette: colors,
  spacing: 4,
  typography: {
    fontFamily: ['Inter'].join(','),
    fontSize: 16
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true
      }
    },
    MuiCssBaseline: {
      styleOverrides: `
       @font-face {
          font-family: 'Inter';
          src: url(${Inter}) format('truetype');
          font-weight: normal;
          font-style: normal;
        }
      `
    },
    MuiAutocomplete: {
      variants: [
        {
          props: {subvariant: 'shaded'},
          style: {
            '.MuiInputBase-input': {
              background: colors.tertiary.main,
              padding: '0.75rem !important',
              paddingLeft: '1rem !important',
              '::placeholder': {color: colors.secondary.main}
            },
            '& .MuiAutocomplete-inputRoot': {paddingRight: '0px !important'},
            '.MuiInputLabel-shrink': {transform: 'translate(0, -0.5rem)'}
          }
        }
      ]
    },
    MuiButton: {
      styleOverrides: {root: {textTransform: 'none'}},
      variants: [
        {
          props: {subvariant: 'fat'},
          style: {padding: '1rem'}
        },
        {
          props: {subvariant: 'link'},
          style: {
            cursor: 'pointer',
            fontSize: '1rem',
            textDecoration: 'underline',
            ':hover': {textDecoration: 'underline'}
          }
        }
      ]
    },
    MuiCardContent: {styleOverrides: {root: {':last-child': {paddingBottom: '1rem'}}}},
    MuiCheckbox: {
      styleOverrides: {
        root: {
          marginLeft: '0.25rem',
          marginRight: '0.25rem',
          paddingBottom: '0rem',
          paddingLeft: '0.25rem',
          paddingTop: '0rem',
          paddingRight: '0.25rem'
        }
      }
    },
    MuiFormControl: {
      variants: [
        {
          props: {subvariant: 'shaded'},
          style: {
            width: '100%',
            '.MuiInputLabel-root': {transform: 'translate(0, -0.5rem)'},
            '.MuiInputBase-input': {
              background: colors.tertiary.main,
              padding: '0.75rem',
              paddingLeft: '1rem'
            }
          }
        }
      ]
    },
    MuiIconButton: {styleOverrides: {root: {':hover': {backgroundColor: 'rgba(0,0,0,0)'}}}},
    MuiSvgIcon: {
      variants: [
        {
          props: {variant: 'account'},
          style: {color: colors.primary.main}
        }
      ]
    },
    MuiTab: {
      styleOverrides: {root: {textTransform: 'none'}},
      variants: [
        {
          props: {subvariant: 'account'},
          style: {
            minHeight: '45px',
            paddingLeft: '2rem',
            paddingRight: '2rem',
            '&.Mui-selected': {
              backgroundColor: colors.primary.main,
              color: colors.white.main
            }
          }
        }
      ]
    },
    MuiTextField: {
      variants: [
        {
          props: {subvariant: 'shaded'},
          style: {
            '.MuiInputBase-input': {
              background: colors.tertiary.main,
              padding: '0.75rem',
              paddingLeft: '1rem',
              '::placeholder': {color: colors.secondary.main}
            },
            '.MuiInputLabel-shrink': {transform: 'translate(0, -0.5rem)'}
          }
        }
      ]
    },
    MuiTypography: {
      variants: [
        {
          props: {subvariant: 'link'},
          style: {
            cursor: 'pointer',
            fontWeight: 600,
            textDecoration: 'underline'
          }
        }
      ]
    }
  }
});
