import {useEffect, useState} from 'react';
import * as yup from 'yup';
import {Box, Typography} from '@mui/material';
import {enqueueSnackbar} from 'notistack';
import {Loader} from 'components/Loader/Loader';
import {csvParser, readFile, removeArrayDuplicates} from 'utils/helpers';
import {useImperativeRef} from 'hooks/useImperativeRef';
import {useAdminStore} from 'stores/hooks/useAdminStore';
import {ConfirmUserDetails} from './ConfirmUserDetails/ConfirmUserDetails';
import {ErrorMessage} from 'components/Messages/ErrorMessage';
import {WarningMessage} from 'components/Messages/WarningMessage';
import {Footer} from '../../components/Footer/Footer';
import {SubmitBtn} from '../../components/SubmitBtn/SubmitBtn';
import {Files} from '../../components/Files/Files';
import {FilesErrors} from '../../components/FilesErrors/FilesErrors';
import {CustomDragAndDrop} from '../../components/CustomDragAndDrop/CustomDragAndDrop';
import styles from '../../Admin.module.css';

const schema = yup
  .object({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    email: yup.string().email().required(),
    is_staff: yup.boolean()
  })
  .required();

export const BulkCreation = ({
  onUsers,
  organizationId,
  onHideModal,
  onShowModal
}) => {
  const {organizations, users: storedUsers} = useAdminStore();
  const alreadyCreatedUsers = storedUsers.data || [];
  const [confirmUserDetailsRef, setConfirmUserDetailsRef] = useImperativeRef(
    null,
    {
      beforeCall: onHideModal,
      afterCall: onShowModal
    },
    ['open']
  );
  const [noUploadedUsersError, setNoUploadedUsersError] = useState();
  const [openUserFileErrors, setOpenUserFileErrors] = useState(false);
  const [fileErrors, setFileErrors] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState({});

  useEffect(() => {
    if (Object.keys(users).length > 0) {
      setNoUploadedUsersError();
    }
  }, [users]);

  useEffect(() => {
    if (fileErrors) {
      const newFileErrors = {};
      Object.keys(users).forEach((filename) => {
        if (fileErrors[filename]) {
          newFileErrors[filename] = fileErrors[filename];
        }
      });

      if (Object.keys(newFileErrors).length === 0) {
        setFileErrors(undefined);
        return;
      }

      setFileErrors(newFileErrors);
    }
  }, [users]);

  const checkEmail = (email, path) => {
    const result = alreadyCreatedUsers.find((user) => user.email === email);

    return result ? {
      path,
      pathErrorMessages: [`Current email: "${email}", already registered.`],
      currentValue: email
    } : undefined;
  };

  const checkUserAlreadyInOrganization = (email, path) => {
    const user = alreadyCreatedUsers.find((user) => user.email === email);
    if (!user) {
      return;
    }

    return user.organization ? {
      path,
      pathErrorMessages: [`The current user is already a member of the organization: ${user.organization.name}.`],
      currentValue: user.organization.id
    } : undefined;
  };

  const checkOrganization = (organizationId, path) => {
    if (!organizationId) {
      return;
    }
    const result = organizations?.data?.find((organization) => organization.id === organizationId);

    return !result ? {
      path,
      pathErrorMessages: [`Organization with id: "${organizationId}", does not exist.`],
      currentValue: organizationId
    } : undefined;
  };

  const checkUserObj = async (obj, fileName, newUsers, errors, index) => {
    try {
      // eslint-disable-next-line no-await-in-loop
      await schema.validate(
        obj, {abortEarly: false}
      );

      const currentErrors = [];
      const emailCheckError = checkEmail(obj.email, 'email');
      if (obj?.organization_id) {
        const membershipCheckError = checkUserAlreadyInOrganization(obj.email, 'organization_id');
        if (membershipCheckError) {
          currentErrors.push(membershipCheckError);
        }
      }
      if (emailCheckError) {
        currentErrors.push(emailCheckError);
      }

      if (!organizationId) {
        const organizationCheckError = checkOrganization(obj?.organization_id, 'organization_id');
        if (organizationCheckError) {
          currentErrors.push(organizationCheckError);
        }
      }

      if (currentErrors.length) {
        if (errors[fileName] === undefined) {
          errors[fileName] = [];
        }
        errors[fileName].push({
          index,
          errors: currentErrors
        });
        return;
      }

      newUsers.push([fileName, {...obj, is_admin: obj?.is_admin === 'true', userIndex: index}]);
    } catch (err) {
      if (errors[fileName] === undefined) {
        errors[fileName] = [];
      }

      errors[fileName].push({
        index,
        errors: err.inner.map((error) => {
          const path = error.path;
          const pathErrorMessages = error.errors;
          const currentValue = error.value;

          return {path, pathErrorMessages, currentValue};
        })
      });
    }
  };

  const parseUsers = async (decodedFiles) => {
    const errors = {};
    const newUsers = [];

    for (let i = 0; i < decodedFiles.length; i++) {
      const decodedFile = decodedFiles[i];
      try {
        // eslint-disable-next-line no-await-in-loop
        const parsed = await csvParser(decodedFile.name, decodedFile.value);

        if (Array.isArray(parsed)) {
          for (let j = 0; j < parsed.length; j++) {
            // eslint-disable-next-line no-await-in-loop
            await checkUserObj(parsed[j], decodedFile.fileName, newUsers, errors, j);
          }
        } else {
          // eslint-disable-next-line no-await-in-loop
          await checkUserObj(parsed, decodedFile.fileName, newUsers, errors, 0);
        }
        if (!newUsers.find(([fileName]) => decodedFile.fileName === fileName)) {
          newUsers.push([decodedFile.fileName]);
        }
      } catch (error) {
        let errorMessage = `File ${decodedFile.fileName} is broken.`;
        if (error) {
          errorMessage = `File ${decodedFile.fileName} errorMessage: ${error}`;
        }

        enqueueSnackbar(errorMessage, {
          key: decodedFile.fileName,
          variant: 'error',
          autoHideDuration: 3500
        });
      }
    }

    setFileErrors((prev) => ({...prev, ...errors}));
    if (Object.keys(errors).length > 0) {
      enqueueSnackbar('Some files contain invalid user objects.', {
        key: 'files-validation-errors',
        variant: 'warning',
        autoHideDuration: 2500
      });
    }

    if (Object.keys(errors).length === 0 && !fileErrors) {
      setFileErrors();
    }

    return newUsers;
  };

  const addUsers = (newUsers) => {
    const result = {...users};
    newUsers.forEach(([fileName, user]) => {
      if (result[fileName] === undefined) {
        result[fileName] = [];
      }

      result[fileName].push(user);
    });

    setUsers(result);
  };

  const onFiles = async (files) => {
    setLoading(true);
    const decodedFiles = await Promise.all(files.map((file) => readFile(file)));

    const newUsers = await parseUsers(decodedFiles);
    if (newUsers) {
      addUsers(newUsers);
    }

    setLoading(false);
  };

  const removeUploadedUsers = (filename) => {
    setUsers((prev) => {
      const cloned = {...prev};

      delete cloned[filename];

      return cloned;
    });
  };

  const prepareResponseErrors = (errors, usersFilenames, failedUsers) => {
    const preparedErrors = {};

    errors
      .filter((errorObj) => Object.keys(errorObj).length)
      .forEach((error, index) => {
        Object.entries(error).forEach(([field, messages]) => {
          const failedUser = failedUsers?.[index];
          if (!failedUser) {
            return;
          }
          const error = {
            path: field,
            pathErrorMessages: messages,
            currentValue: failedUser.user?.[field] || ''
          };

          const filename = usersFilenames[failedUser.index];
          if (!preparedErrors[filename]) {
            preparedErrors[filename] = [];
          }

          const errorObj = preparedErrors[filename].find((error) => error.index === failedUser.user.userIndex);
          if (errorObj) {
            errorObj.errors.push(error);
          } else {
            preparedErrors[filename].push({
              index: failedUser.user.userIndex,
              errors: [error]
            });
          }
        });
      });

    return preparedErrors;
  };

  const mergeErrors = (main, sub) => {
    const result = {...main};
    Object.entries(sub).forEach(([filename, fileErrors]) => {

      fileErrors.forEach((errorsObj) => {
        const mainError = result[filename]?.find((item) => item.index === errorsObj.index);

        if (mainError) {
          errorsObj.errors.forEach((error) => {
            const pathErrorObj = mainError.errors.find(({path}) => error.path === path);

            pathErrorObj.pathErrorMessages = removeArrayDuplicates(
              pathErrorObj.pathErrorMessages
                .concat(error.pathErrorMessages)
            );
          });
        } else {
          if (!result[filename]) {
            result[filename] = [];
          }
          result[filename].push(errorsObj);
        }

      });
    });

    return result;
  };

  const onSubmit = async () => {
    const usersFilenames = {};
    let index = 0;

    Object.entries(users).forEach(([filename, usersArray]) => {
      usersArray.forEach(() => {
        usersFilenames[index] = filename;
        index += 1;
      });
    });

    const usersArray = Object.values(users)
      .flat(1)
      .filter(Boolean)
      .map((user) => organizationId ? {...user, organization_id: organizationId} : user)
      .map((user) => {
        const obj = {...user};
        if (!obj.organization_id) {
          delete obj['organization_id'];
        }

        return obj;
      });

    if (usersArray.length === 0) {
      setNoUploadedUsersError('No uploaded users found.');
      return;
    } else {
      setNoUploadedUsersError();
    }
    const result = await confirmUserDetailsRef.current.open(usersArray);

    if (result?.confirmed) {
      let usersState = {};
      if (result?.isError) {

        const errors = result?.responseData;
        const responseErrors = prepareResponseErrors(errors, usersFilenames, result.failedUsers);

        setFileErrors(mergeErrors(fileErrors, responseErrors));

        result.failedUsers.forEach(({user, index}) => {
          const filename = usersFilenames[index];
          if (!usersState[filename]) {
            usersState[filename] = [];
          }

          usersState[filename].push(user);
        });
      }

      setUsers(usersState);
      onUsers(result?.updateUsers, !!Object.keys(usersState).length);
      storedUsers.execute();
    }
  };

  const showErrorsDetails = () => {
    setOpenUserFileErrors(true);
  };

  const onCloseUserFileErrors = () => {
    setOpenUserFileErrors(false);
  };

  return (
    <Box className={styles.bulkCreation}>
      <FilesErrors
        errors={fileErrors}
        handleClose={onCloseUserFileErrors}
        open={openUserFileErrors}
      />
      <ConfirmUserDetails ref={setConfirmUserDetailsRef}/>
      <Loader absolute={true} loading={loading}/>
      <CustomDragAndDrop onFiles={onFiles}/>
      <Files
        files={Object.keys(users)}
        onRemove={removeUploadedUsers}
      />
      {!!noUploadedUsersError && <ErrorMessage message={noUploadedUsersError}/>}
      {
        !!fileErrors && (
          <Box>
            <WarningMessage message="Some files contain invalid user objects, to see more details click" />
            <Typography
              className={styles.showMoreErrorFilesDetails}
              onClick={showErrorsDetails}
            >
              Show Details
            </Typography>
          </Box>
        )
      }
      <Footer
        display="flex"
        justifyContent="flex-end"
      >
        <SubmitBtn onClick={onSubmit} label="Upload"/>
      </Footer>
    </Box>
  );
};
