import {useState, useEffect} from 'react';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {capitalize} from 'utils/helpers';
import vesselIcon from 'assets/icons/icon_ship.svg';
import companyIcon from 'assets/icons/icon_company.svg';
import personIcon from 'assets/icons/icon_person.svg';
import styles from './EntityType.module.css';

export const EntityType = (props) => {
  const {entityType, disableLabel} = props;
  const [imageSource, setImageSource] = useState(null);

  useEffect(() => {
    if (entityType === 'company') {
      setImageSource(companyIcon);
    } else if (entityType === 'person') {
      setImageSource(personIcon);
    } else if (entityType === 'vessel') {
      setImageSource(vesselIcon);
    } else {
      setImageSource(null);
    }
  }, [entityType]);

  return (
    <Box className={styles.container}>
      {
        !!imageSource && (
          <Icon className={styles.entityTypeImage}>
            <img src={imageSource} height="20px" alt={`${entityType}-icon`}/>
          </Icon>
        )
      }
      {
        !disableLabel && (
          <Typography className={styles.displayLabel}>
            {capitalize(entityType)}
          </Typography>
        )
      }
    </Box>
  );
};
