import {
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {enqueueSnackbar} from 'notistack';
import {
  Box,
  Typography,
  IconButton,
  Button
} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import {organizationServices} from 'api/services/organizations';
import {useAdminItemStore} from 'stores/hooks/useAdminItemStore';
import {AdminItemStoreProvider} from 'stores/contexts/AdminItemStore';
import {useAdminStore} from 'stores/hooks/useAdminStore';
import {EditOrganization} from './EditOrganization/EditOrganization';
import {AddOrganization} from './AddOrganization/AddOrganization';
import {Loader} from 'components/Loader/Loader';
import {DataTable} from 'components/DataTable/DataTable';
import {Pagination} from 'components/SearchResultsTable/Pagination';
import {CustomInput} from 'components/CustomInput/CustomInput';
import {ColumnHeader} from 'components/DataTable/components/ColumnHeader/ColumnHeader';
import {TableCell} from '../components/Table/TableCell/TableCell';
import {RemoveDialog} from '../components/RemoveDialog/RemoveDialog';
import {ReactComponent as SearchIcon} from 'assets/icons/icon_search.svg';
import {ReactComponent as DeleteIcon} from 'assets/icons/icon_delete.svg';
import {ReactComponent as EditIcon} from 'assets/icons/icon_edit.svg';
import styles from '../Admin.module.css';

export const Organizations = () => (
  <AdminItemStoreProvider>
    <OrganizationsContent />
  </AdminItemStoreProvider>
);

export const OrganizationsContent = () => {
  const {update, setState, ...adminState} = useAdminItemStore();
  const {organizations} = useAdminStore();
  const addOrganization = useRef(null);
  const removeOrganization = useRef(null);
  const editOrganization = useRef(null);
  const theme = useTheme();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (organizations.data) {
      setRows(organizations.data);
    }
  }, [organizations.data]);

  useEffect(() => {
    setState({
      ...adminState,
      count: rows.length
    });
  }, [rows]);

  const requestDeleteModal = async (organizationId) => {
    const success = await removeOrganization.current.open([organizationId]);

    if (success) {
      let deletedOrganization;
      setRows((prev) => {
        const cloned = prev.slice();

        const index = cloned.findIndex((item) => item.id === organizationId);
        if (index !== -1) {
          const [organization] = cloned.splice(index, 1);
          deletedOrganization = organization;
        }

        return cloned;
      });

      if (deletedOrganization) {
        const config = {
          preventDuplicate: true,
          key: `remove-organization-${organizationId}`,
          autoHideDuration: 2500,
          variant: 'success'
        };

        enqueueSnackbar(`Organizations ${deletedOrganization?.name || ''}, successfully deleted.`, config);
      }
    }
  };

  const requestAddOrganizationModal = async () => {
    const organizations = await addOrganization.current.open();
    if (organizations?.length) {
      setRows(organizations.concat(rows));
    }
  };

  const requestEditModal = async (organizationId) => {
    const organization = await editOrganization.current.open(organizationId);
    if (organization) {
      const organizationData = organizations.data || [];
      const index = organizationData.findIndex((organization) => organization.id === organizationId);
      organizationData[index] = {...organization};

      organizations.update(organizationData);
    }
  };

  const columns = useMemo(() => [
    {
      field: 'name',
      renderHeader: () => <ColumnHeader label="Organizations Name"/>,
      disableColumnMenu: true,
      minWidth: 200,
      flex: 1,
      renderCell: ({value}) => <TableCell label={value || ''} color="secondary"/>
    },
    {
      field: 'members_count',
      renderHeader: () => <ColumnHeader label="Users"/>,
      disableColumnMenu: true,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({value}) => <TableCell label={value || 0} color="secondary"/>
    },
    {
      field: 'actions',
      renderHeader: () => <ColumnHeader label="Actions" justifyContent="center"/>,
      disableColumnMenu: true,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: ({row}) => (
        <Box
          display="flex"
          alignItems="center"
        >
          <IconButton
            onClick={
              () => {
                requestEditModal(row.id);
              }
            }
          >
            <EditIcon color="secondary"/>
          </IconButton>
          <IconButton
            onClick={
              () => {
                requestDeleteModal(row.id);
              }
            }
          >
            <DeleteIcon color="secondary"/>
          </IconButton>
        </Box>
      )
    }
  ], []);

  const onPageSize = (pageSize) => {
    setState({
      ...adminState,
      pageSize,
      page: 0
    });
  };

  const onPage = (page) => {
    update('page', page);
  };

  const filteredRows = useMemo(() => rows.filter((organization) => {
    const currentSearchQuery = adminState.search.toLowerCase();
    const organizationName = organization.name.toLowerCase();

    return organizationName.indexOf(currentSearchQuery) !== -1;
  }), [adminState.search, rows]);

  const searchQueryOnInput = (e) => {
    setState({
      ...adminState,
      search: e.target.value,
      page: 0
    });
  };

  const onSort = (sort) => {
    if (sort) {
      setState({
        ...adminState,
        ordering: sort,
        page: 0
      });
    }
  };

  // eslint-disable-next-line max-len
  const removeDialogText = 'Are you sure you want to remove this organization?<br/>(This does not remove the users within the organization)';

  return (
    <Box className={styles.adminContainer}>
      <Loader loading={organizations.loading} absolute={true}/>
      <RemoveDialog
        ref={removeOrganization}
        title="Remove Organizations"
        description={removeDialogText}
        submitBtnText="Remove"
        service={organizationServices.removeOrganization}
      />
      <EditOrganization ref={editOrganization}/>
      <AddOrganization ref={addOrganization}/>
      <Box
        className={styles.tableHeader}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <CustomInput
          inputClassName={styles.searchInput}
          value={adminState.search}
          autoComplete="one-time-code"
          className={styles.searchInputContainer}
          placeholder="Search Organizations..."
          onChange={searchQueryOnInput}
          InputProps={
            {
              startAdornment: (
                <SearchIcon
                  className={styles.searchIcon}
                  style={{fill: theme.palette.secondary.main}}
                />
              )
            }
          }
        />
        <Button
          className={styles.addBtn}
          variant="contained"
          type="submit"
          onClick={requestAddOrganizationModal}
          sx={
            {background: theme.palette.red.main}
          }
        >
          <AddIcon className={styles.addBtnIcon}/>
          <Typography className={styles.addBtnText}>Add Organization</Typography>
        </Button>
      </Box>
      <DataTable
        columns={columns}
        rows={filteredRows}
        autoHeight={true}
        dataGridProps={
          {
            getRowId: (row) => row.id,
            loading: false,
            sortingMode: 'client',
            onSortModelChange: (sortModel) => {
              onSort(sortModel?.[0]);
            },
            pagination: true,
            paginationMode: 'client',
            rowCount: adminState.count,
            page: adminState.page,
            hideFooterPagination: true,
            pageSize: adminState.pageSize,
            sortModel: adminState.ordering ? [adminState.ordering] : undefined
          }
        }
        dataGridStyles={
          {
            '& .MuiDataGrid-columnHeaders': {border: 'none'},
            '& .MuiDataGrid-row': {borderBottom: `1px solid ${theme.palette.gray.gray100}`}
          }
        }
        emptyOverlayProps={{text: 'There are currently no search results.'}}
      />
      {
        !organizations.loading && (
          <Pagination
            pagination={
              {
                pageSize: adminState.pageSize,
                page: adminState.page,
                count: filteredRows.length
              }
            }
            onPageSize={onPageSize}
            onPage={(page) => onPage(page - 1)}
          />
        )
      }
    </Box>
  );
};
