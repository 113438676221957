import {
  decodeDelimitedArray,
  encodeDelimitedArray,
  decodeString,
  encodeString
} from 'serialize-query-params';
import {getValue, setValue} from './helpers';

/* configs */
export const customStringParam = (address, defaultValue) => ({
  encode: encodeString,
  decode: decodeString,
  paramType: 'string',
  address,
  defaultValue
});

export const customArrayParam = (address, defaultValue) => ({
  encode: (arr) => encodeDelimitedArray(arr || [], '+'),
  decode: (arrStr) => decodeDelimitedArray(arrStr, '+'),
  paramType: 'array',
  address,
  defaultValue
});

export const customMapParam = (address, defaultValue) => ({
  encode: (obj) => {
    const arr = Object.entries(obj)
      .filter((item) => item[1])
      .map(([key]) => key);

    return encodeDelimitedArray(arr, '+');
  },
  decode: (arrStr) => {
    const keys = decodeDelimitedArray(arrStr, '+') || [];
    const value = {...defaultValue};
    Object.keys(value).forEach((key) => {
      value[key] = false;
    });

    keys.forEach((key) => {
      if (value[key] !== undefined) {
        value[key] = true;
      }
    });

    return value;
  },
  paramType: 'map',
  address,
  defaultValue
});

export const customOrderingParam = (address, defaultValue) => ({
  encode: (obj) => {
    if (!obj || !obj?.field) {
      return '';
    }

    const sortPrefix = obj.sort === 'asc' ? '' : '-';
    return sortPrefix + obj.field;
  },
  decode: (str) => {
    if (str === '' || !str) {
      return {
        sort: '',
        field: ''
      };
    }

    const sort = str[0] === '-' ? 'desc' : 'asc';
    const field = str[0] === '-' ? str.slice(1) : str;
    return {
      field,
      sort
    };
  },
  paramType: 'obj',
  address,
  defaultValue
});

export const customNumberParam = (address, defaultValue) => ({
  encode: (num) => num !== undefined && num > 0 ? String(num) : '',
  decode: (str) => {
    if (!str || str === '') {
      return 0;
    }
    return parseInt(str);
  },
  paramType: 'number',
  address,
  defaultValue
});

export const customPageSizeParam = (address, defaultValue) => ({
  encode: (num) => !num || num === 25 ? '' : String(num),
  decode: (str) => {
    if (!str || str === '') {
      return 25;
    }
    return parseInt(str);
  },
  paramType: 'number',
  address,
  defaultValue
});

export const makeEncodeDataFunction = (searchQueryConfig) => (data) => {
  const searchParams = new URLSearchParams();
  Object.entries(searchQueryConfig).forEach(([key, {address, encode, defaultValue}]) => {
    const value = getValue(address, data) || defaultValue;

    if (JSON.stringify(value) === JSON.stringify(defaultValue)) {
      return;
    }
    searchParams.set(key, encode(value));
  });

  return searchParams;
};

export const makeDecodeSearchParamsFunction = (searchQueryConfig) => (searchParams, data) => {
  const newFilters = {...data};

  Object.entries(searchQueryConfig).forEach(([key, {address, decode, defaultValue}]) => {
    const value = searchParams.get(key);

    const decodedValue = JSON.parse(JSON.stringify(value ? decode(value) || defaultValue : defaultValue));

    setValue(newFilters, address, decodedValue);
  });

  return newFilters;
};
