import {useEffect, useMemo, useState} from 'react';
import cn from 'classnames';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {AllCheckbox, FilterCheckbox} from 'components/EntityFilterCheckbox/EntityFilterCheckbox';
import styles from './QuickSearchFilters.module.css';

export const Section = (props) => {
  const {
    title,
    onChange,
    value,
    options,
    disabled,
    getLabel
  } = props;
  const [selected, setSelected] = useState(value || []);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const handleChange = (option, checked) => {
    const newSelected = selected.slice();
    if (checked) {
      if (!selected.includes(option)) {
        newSelected.push(option);
      }
    } else {
      const index = selected.indexOf(option);
      if (index !== -1) {
        newSelected.splice(index, 1);
      }
    }
    setSelected(newSelected);
    onChange(newSelected);
  };

  const selectAll = (checked) => {
    const newSelected = checked ? options : [];

    setSelected(newSelected);
    onChange(newSelected);
  };

  const isAllSelected = useMemo(() => options.length === selected.length, [selected, options]);

  return (
    <Box>
      <Box className={styles.titleContainer}>
        <Typography
          className={cn(styles.sectionTitle, disabled && styles.disabledSectionTitle)}
          color="secondary.main"
          component="h6"
        >
          {title}
        </Typography>
      </Box>
      <Grid container className={styles.sectionBody}>
        <AllCheckbox
          checked={isAllSelected}
          onChange={selectAll}
          disabled={disabled}
          labelClassName={styles.selectAllLabel}
        />
        {
          options.map((option) => {
            if (option !== 'all') {
              return (
                <Grid item key={option}>
                  <FilterCheckbox
                    label={getLabel(option)}
                    checked={selected.includes(option)}
                    onChange={(value) => handleChange(option, value)}
                    disabled={disabled}
                  />
                </Grid>
              );
            }
          })
        }
      </Grid>
    </Box>
  );
};
