import {useState} from 'react';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

export const CollapseList = ({className, listItemClassName, sx, listItemSX, options, maxSize}) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <List
      disablePadding={true}
      sx={sx}
      className={className}
    >
      {
        options.slice(0, open ? options.length : maxSize).map((element, index) => (
          <ListItem
            key={index}
            sx={listItemSX}
            className={listItemClassName}
          >
            {element}
          </ListItem>
        ))
      }
      {
        options.length > maxSize && (
          <Typography
            variant="body2"
            color="secondary"
            onClick={toggleOpen}
            sx={
              {
                cursor: 'pointer',
                fontSize: '14px',
                textDecoration: 'underline'
              }
            }
          >
            {open ? 'Hide' : 'Show more'}
          </Typography>
        )
      }
    </List>
  );
};
