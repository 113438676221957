import moment from 'moment';

moment.updateLocale('en', {
  relativeTime: {
    past: '%s',
    s: '%ds',
    m: '%dm',
    mm: '%dm',
    h: '%dh',
    hh: '%dh',
    d: '%dd',
    dd: '%dd',
    M: '%dM',
    MM: '%dM',
    y: '%dy',
    yy: '%dy'
  }
});

export {moment};

export function inFuture(time) {
  return time > Math.ceil(Date.now() / 1000);
}

export function formatDate(time) {
  return moment(time).format('MM/DD/YYYY');
}
