import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

export const UnfavoriteConfirmation = (props) => {
  const {menuAnchor, onClick, onClose} = props;

  return (
    <Menu
      anchorEl={menuAnchor}
      open={Boolean(menuAnchor)}
      onClose={onClose}
      sx={
        {'.MuiList-root': {padding: '0px'}}
      }
      PaperProps={
        {
          style: {
            width: '225px',
            padding: '1rem'
          }
        }
      }
      TransitionProps={{exit: false}}
    >
      <Stack spacing={5}>
        <Typography variant="body2">
          Did you mean to unfavorite this entity?
        </Typography>
        <Stack direction="row" spacing={5}>
          <Button
            variant="contained"
            onClick={() => onClick('confirmation')}
          >
            Unfavorite
          </Button>
          <Button onClick={onClose}>
            No
          </Button>
        </Stack>
      </Stack>
    </Menu>
  );
};
