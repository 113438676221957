import {useMemo} from 'react';
import cn from 'classnames';
import {Button, Typography} from '@mui/material';
import styles from './CancelBtn.module.css';

export const CancelBtn = ({label, prefix, onClick, ...props}) => {
  const labelComponent = useMemo(() => typeof label === 'string' ? (
    <Typography className={styles.cancelBtnText}>
      {label}
    </Typography>
  ) : label, [label]);

  return (
    <Button
      variant="text"
      onClick={onClick}
      {...props}
      className={cn(styles.cancelBtn, props.className)}
    >
      {prefix}
      {labelComponent}
    </Button>
  );
};
