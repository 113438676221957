import cn from 'classnames';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import styles from './Loader.module.css';

export const Loader = ({loading, title, absolute}) => (
  <div className={
    cn(
      styles.loaderContainer,
      !loading && styles.hideLoader,
      absolute && styles.loaderAbsolute
    )
  }
  >
    <CircularProgress color="inProgress"/>
    {title}
  </div>
);
