import {renderToString} from 'react-dom/server';
import Box from '@mui/material/Box';
import styles from './NodeBadge.module.css';

export const makeNodeBadge = (cy, ele, num) => {
  let badge = ele.popper({
    content: () => {
      const div = document.createElement('div');
      const content = renderToString(
        <Box
          className={styles.badgeBox}
        >
          {num}
        </Box>
      );

      div.innerHTML = content;
      div.classList.add(
        `canvas-ele-${ele._private?.data?.id.replaceAll(':', '-')}`
      );

      div.style.zIndex = 1;

      const cyContainer = cy.container();
      const cyCanvas = cyContainer.querySelector('canvas');

      cyCanvas.parentNode.append(div);

      return div;
    },
    popper: {
      placement: 'right-start',
      strategy: 'absolute'
    }
  });

  let update = () => {
    badge.update();
  };

  ele.on('position', update);
  ele.on('scale', update);

  cy.on('pan zoom resize', update);
  ele.badge = badge;
  return ele;
};
