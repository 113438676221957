import {useMemo} from 'react';
import {Box, Typography} from '@mui/material';
import cn from 'classnames';
import {renderToString} from 'react-dom/server';
import {ReactComponent as WarningIcon} from 'assets/icons/icon_warning_none.svg';
import {ReactComponent as IUUListedIcon} from 'assets/icons/icon_iuu_status.svg';
import styles from './NodeLabel.module.css';

const NodeLabel = ({label, node, bgColor, className}) => {
  const style = useMemo(() => {
    let styles = {background: bgColor};
    if (label.length > 10) {
      styles = {
        ...styles,
        '--node-tooltip-text': `"${label}"`,
        '--node-tooltip-color': '#ffffff',
        '--node-tooltip-background': bgColor
      };
    }

    return styles;
  }, [label]);

  const visibleLabel = useMemo(() => {
    if (label.length > 10) {
      return `${label.slice(0, 10)}...`;
    }
    return label;
  }, [label]);

  const infoIcon = useMemo(() => {
    const listed = node?.entity?.iuu_status === 'listed';
    const active_sanctions = node?.entity?.sanction_status === 'active_sanctions';

    if (listed) {
      return <IUUListedIcon className={styles.labelIcon} style={{fill: bgColor, borderColor: bgColor}}/>;
    } else if (active_sanctions) {
      return <WarningIcon className={styles.labelIcon} style={{fill: bgColor, borderColor: bgColor}}/>;
    }

    return null;
  }, [node]);

  return (
    <div className={styles.labelWrapper}>
      <p
        style={style}
        title={label}
        className={cn(styles.label, className)}
      >
        {visibleLabel}
      </p>
      {infoIcon}
    </div>
  );
};

export const NodeLabelTooltip = ({title, backgroundColor}) => {
  return (
    <Box className={styles.tooltip} style={{backgroundColor}}>
      <Typography color="white">{title}</Typography>
    </Box>
  );
};

export const renderNodeLabel = (label, node, bgColor, className) => {
  return renderToString(<NodeLabel label={label} node={node} bgColor={bgColor} className={className}/>);
};
