export const removeChildNodes = (ele, classPrefix, display, index = false) => {
  const successors = ele?.successors()?.targets();

  successors.forEach((el) => {
    removeChildNodes(el, classPrefix, display);
  });

  if (ele._private?.data?.id) {
    const id = ele._private?.data?.id.replaceAll(':', '-');
    const prevDivs = document.querySelectorAll(
      `.${classPrefix}-${id}`
    );

    if (prevDivs) {
      prevDivs.forEach((prevDiv) => prevDiv.remove());
    }
    if (index) {
      return;
    }

    const labels = document.querySelectorAll(
      `.node-label-${id}`
    );
    if (labels) {
      labels.forEach((label) => {
        label.parentElement.style.display = display === 'none' ? 'none' : 'block';
      });
    }
  }
};

export const toggleNodeCollapse = (
  event,
  badges,
  getTargetsBySourceId,
  getSourcesOfTarget,
  makeBadge,
  getCountOfTargets
) => {
  const successors = event.target?.successors();
  const successorTargets = successors.targets();
  [...successors].forEach((successor) => {
    successor.badge?.destroy();
  });

  const targets = getTargetsBySourceId(event.target._private.data.id);

  const countOfHiddenNodes = targets.reduce((acc, item) => {
    if (item.hidden()) {
      acc += 1;
    }
    return acc;
  }, 0);

  let display;
  if (countOfHiddenNodes === targets.length) {
    display = 'element';
  } else {
    display = 'none';
  }

  removeChildNodes(event.target, 'canvas-ele', display, true);

  if (display === 'none') {
    successorTargets.forEach((item) => {
      item.hide();
    });
  } else {
    successorTargets.forEach((item) => {
      item.show();
    });
  }

  const newBadges = [];

  const sources = [];
  successorTargets.forEach((successorTarget) => {
    const successorTargetSources = getSourcesOfTarget(successorTarget);

    successorTargetSources.forEach((item) => {
      if (sources.find((n) => item._private.data.id === n._private.data.id)) {
        return;
      }
      sources.push(item);
    });
  });

  sources.forEach((source) => {
    const index = badges.findIndex((item) =>
      item._private.data.id === source._private.data.id
    );

    if (index !== -1) {
      const [badge] = badges.splice(index, 1);
      badge?.badge?.destroy();
      badge?.badge?.forceUpdate();
      return;
    }

    const {count, hiddenCount} = getCountOfTargets(source._private.data.id);
    if (display === 'element' && hiddenCount !== count) {
      if (source.hidden() || hiddenCount === 0) {
        return;
      }
      newBadges.push(makeBadge(source));
    } else if (hiddenCount === count) {
      if (source.hidden()) {
        return;
      }
      newBadges.push(makeBadge(source));
    }
  });
  return [...badges, ...newBadges];
};

export const closeTippy = (tippyRef) => (ele) => {
  [...tippyRef.current]
    .filter((badge) => badge._private?.data?.id === ele?._private.data.id)
    .forEach((badge) => {
      removeChildNodes(ele, 'canvas-popup-element');
      badge.badge?.destroy();
    });

  removeChildNodes(ele, 'canvas-popup-element');
  ele.badge.destroy();
  ele.badge = undefined;

  tippyRef.current = [...tippyRef.current].filter(
    (badge) => badge._private?.data?.id !== ele?._private.data.id
  );
};
