import {useState} from 'react';
import {
  TextField,
  Box,
  Button,
  Typography
} from '@mui/material';
import cn from 'classnames';
import {useTheme} from '@mui/material/styles';
import {debounce} from 'lodash';
import {useSearchStore} from 'stores/hooks/useSearchStore';
import {ReactComponent as SearchIcon} from 'assets/icons/icon_search.svg';
import styles from './SearchInput.module.css';

export const SearchInput = ({
  onSubmit,
  defaultValue,
  onSearch: propsOnSearch,
  className,
  searchIconClassName
}) => {
  const {
    searchFilters,
    updateFilter
  } = useSearchStore();
  const theme = useTheme();
  const [value, setValue] = useState(defaultValue || searchFilters.textFilters.name || '');

  const clear = () => {
    setValue('');
    propsOnSearch
      ? propsOnSearch('')
      : updateFilter('textFilters', {
        ...searchFilters.textFilters,
        name: ''
      });
  };

  const onSearch = () => {
    let currentValue = value;

    const debouncedFunction = debounce(() => {
      propsOnSearch
        ? propsOnSearch(currentValue)
        : updateFilter('textFilters', {
          ...searchFilters.textFilters,
          name: currentValue
        });
    }, 500);

    return (e) => {
      setValue(e.target.value);
      currentValue = e.target.value;
      debouncedFunction();
    };

  };

  return (
    <TextField
      placeholder="Search by name or other unique identifiers"
      value={value}
      InputProps={
        {
          endAdornment: (
            <Box
              display="flex"
              alignItems="center"
              className={styles.endAdornmentContainer}
            >
              {!!value?.length && (
                <Typography
                  className={styles.clearBtn}
                  onClick={clear}
                  style={
                    {color: theme.palette.gray.gray900}
                  }
                >
                  Clear
                </Typography>
              )}
              <Button
                className={styles.searchBtn}
                fullWidth
                variant="contained"
                onClick={onSubmit}
                sx={
                  {background: theme.palette.red.main}
                }
              >
                <SearchIcon
                  className={cn(styles.searchIcon, searchIconClassName)}
                  style={{fill: theme.palette.white.main}}
                />
              </Button>
            </Box>
          )
        }
      }
      sx={
        {color: theme.palette.gray.gray900}
      }
      variant="outlined"
      onChange={onSearch()}
      className={cn(styles.textField, className)}
    />
  );
};

