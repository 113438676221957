import {useState} from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import {useApi} from 'hooks/useApi';
import {useBroadcaster} from 'hooks/useBroadcaster';
import {entitiesService} from 'api/services/entities';
import {QuickSearch} from './components/QuickSearch/QuickSearch';
import {RecentlyViewed} from './components/RecentlyViewed/RecentlyViewed';
import {Favorites} from './components/Favorites/Favorites';
import {DetailsPanel} from 'components/DetailsPanel/DetailsPanel';
import {HttpErrorHandler} from 'components/HttpErrorHandler/HttpErrorHandler';
import {Loader} from 'components/Loader/Loader';
import {CustomTabs} from 'components/CustomTabs/CustomTabs';
import styles from './HomePage.module.css';

export const HOME_PAGE_BROADCASTER = 'homePageBroadcaster';
const RECENTLY_VIEWED = 'Recently Viewed';
const FAVORITES = 'Favorites';

export const HomePage = () => {
  const details = useApi({
    service: entitiesService.details,
    immediate: false
  });
  const [detailsPanelOpen, setDetailsPanelOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [, homeBroadcaster] = useBroadcaster(HOME_PAGE_BROADCASTER);
  const [currentView, setCurrentView] = useState(RECENTLY_VIEWED);

  const onClose = () => {
    setDetailsPanelOpen(false);
  };

  const onClickCell = async (triton_id) => {
    setLoading(true);
    const result = await details.execute(triton_id);
    setLoading(false);
    if (result instanceof Error) {
      return;
    }

    setDetailsPanelOpen(true);
  };

  const changeView = (view) => setCurrentView(view);

  return (
    <>
      <HttpErrorHandler/>
      <Loader loading={loading}/>
      <Container className={styles.container}>
        <Grid className={styles.container} container columnSpacing={5} rowSpacing={10}>
          <Grid item xs={12} md={12}>
            <QuickSearch/>
          </Grid>
          <Grid maxWidth="xl" item xs={12} className={styles.tables}>
            <CustomTabs
              className={styles.tabs}
              tabClassName={styles.tabItem}
              tabs={[RECENTLY_VIEWED, FAVORITES]}
              onChange={changeView}
              activeTab={currentView}
            />
            <RecentlyViewed
              visible={currentView === RECENTLY_VIEWED}
              onClickCell={onClickCell}
              broadcaster={homeBroadcaster}
            />
            <Favorites
              visible={currentView === FAVORITES}
              onClickCell={onClickCell}
              broadcaster={homeBroadcaster}
            />
          </Grid>
        </Grid>
      </Container>
      <DetailsPanel
        open={detailsPanelOpen}
        onClose={onClose}
        data={details.data}
        showNetworkButton={true}
        broadcaster={homeBroadcaster}
      />
    </>
  );
};
