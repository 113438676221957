import {apiClient} from 'api/http';

function organizations() {
  return apiClient.get('/admin/organizations/');
}

function create(body) {
  return apiClient.post('/admin/organizations/', body);
}

function removeOrganization(organizationId) {
  return apiClient.delete(`/organizations/info/${organizationId}/`);
}

function updateOrganization({id, ...body}) {
  return apiClient.put(`/organizations/info/${id}/`, body);
}

function organizationUsers(organizationId) {
  return apiClient.get(`/admin/organizations/${organizationId}/members/`);
}

function addUserToOrganization({organizationId, userId}) {
  return apiClient.post(`/admin/organizations/${organizationId}/members/`, {user_id: userId});
}

function removeUserFromOrganization({organizationId, userId}) {
  return apiClient.delete(`/admin/organizations/${organizationId}/members/${userId}/`);
}

function info(id) {
  return apiClient.get(`/organizations/info/${id}/`);
}

function updateMember(body, ids) {
  return apiClient.put(`/organizations/${ids[0]}/members/${ids[1]}/`, body);
}

function invite(body, id) {
  return apiClient.post(`/organizations/${id}/members/`, body);
}

export const organizationServices = {
  create,
  removeOrganization,
  organizationUsers,
  addUserToOrganization,
  removeUserFromOrganization,
  info,
  updateOrganization,
  updateMember,
  invite,
  organizations
};
