import Slide from '@mui/material/Slide';
import useScrollTrigger from '@mui/material/useScrollTrigger';

export const HideOnScroll = ({children, disable}) => {
  const trigger = useScrollTrigger();

  return (
    <>
      {!!disable && children}
      {
        !disable && (
          <Slide appear={false} direction="down" in={!trigger}>
            {children}
          </Slide>
        )
      }
    </>
  );
};
