import {Box} from '@mui/material';
import {enqueueSnackbar} from 'notistack';
import {useProfileStore} from 'stores/hooks/useProfileStore';
import {AccountInfoContent} from './AccountInfoContent/AccountInfoContent';
import {Loader} from 'components/Loader/Loader';
import styles from './AccountInfo.module.css';

export const AccountInfo = () => {
  const [profile, userProfileStoreDispatch] = useProfileStore();

  const handleUpdate = async (user) => {
    userProfileStoreDispatch({
      type: 'updateUser',
      user
    });

    const config = {
      preventDuplicate: true,
      key: `update-user-profile-${profile.user.id}`,
      autoHideDuration: 2500,
      variant: 'success'
    };

    enqueueSnackbar('Profile changes have been saved.', config);
  };

  return (
    <Box className={styles.accountInfoContainer}>
      {
        !!profile?.user && (
          <AccountInfoContent
            user={profile.user}
            onUpdate={handleUpdate}
          />
        )
      }
      <Loader loading={!profile?.user} absolute={true}/>
    </Box>
  );
};
