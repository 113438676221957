import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router';
import {useTheme} from '@mui/material/styles';
import {
  Box,
  Grid,
  Typography
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import nodeHtmlLabel from 'cytoscape-node-html-label';
import cytoscape from 'cytoscape';
import popper from 'cytoscape-popper';
import cydagre from 'cytoscape-dagre';

import {debounce} from 'lodash';
import $ from 'jquery';
import {entitiesService} from 'api/services/entities';
import {useApi} from 'hooks/useApi';
import {StyledTooltip} from 'components/StyledTooltip/StyledTooltip';
import {makePopper} from './EdgePopover/EdgePopover';
import {makeNodeBadge} from './NodeBadge/NodeBadge';
import cytoscapeStyles, {NodeIcons} from './cytoscapeStyles';
import {removeChildNodes, closeTippy, toggleNodeCollapse} from './networksGraphTools';
import {renderNodeLabel} from './NodeLabel/NodeLabel';
import {capitalize} from 'utils/helpers';

import {ReactComponent as BackButtonIcon} from 'assets/icons/icon_back_btn.svg';
import {ReactComponent as PlusIcon} from 'assets/icons/icon_plus.svg';
import {ReactComponent as MinusIcon} from 'assets/icons/icon_minus.svg';
import {ReactComponent as ResetIcon} from 'assets/icons/icon_reset.svg';
import {ReactComponent as DownloadIcon} from 'assets/icons/icon_download.svg';
import {ReactComponent as InfoIcon} from 'assets/icons/icon_info.svg';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/backdrop.css';
import 'tippy.js/animations/shift-away.css';
import styles from './NetworksGraph.module.css';

cytoscape.use(popper);
cydagre(cytoscape);

nodeHtmlLabel(cytoscape);

export const NetworksGraph = ({network, detailsData, triton_id, onClickNode}) => {
  const theme = useTheme();
  const graphRef = useRef(null);
  const navigate = useNavigate();
  const [cyto, setCyto] = useState({});
  const cytoRef = useRef();
  const badgesRef = useRef([]);
  const tippyRef = useRef([]);
  const [layout, setLayout] = useState('dagre');
  const entityDownload = useApi({
    service: entitiesService.entityDownload,
    immediate: false
  });
  let cy;
  let nodes = network?.nodes.map((x) => ({
    data: {
      id: x.entity.triton_id,
      name: x.entity.label,
      label: x.entity.entity_type
    },
    classes: 'l1'
  }));

  useEffect(() => {
    cytoRef.current = cyto;
  }, [cyto]);

  // Filter out "duplicate edges", i.e edges with the same source and target
  const uniqueEdges = network?.edges.filter(
    (element, index, array) =>
      array.findIndex(
        (a) => a.source === element.source && a.target === element.target
      ) === index
  );

  let edges = uniqueEdges.map((x) => {
    // We'll need to know all sibling edges of each unique edge and create an
    // array from all their relation_types
    const groupEdges = network?.edges.filter(
      ({source, target}) => source === x.source && target === x.target
    );

    return {
      data: {
        id: x.id,
        source: x.source,
        target: x.target,
        relationships: groupEdges.map(({relation_type}) => relation_type)
      },
      classes: 'i'
    };
  });

  const calculateGraphConnectedness = () => {
    const connectionsDct = {};
    edges.forEach((item) => {
      const targetId = item.data.target;
      if (connectionsDct[targetId]) {
        connectionsDct[targetId] += 1;
      } else {
        connectionsDct[targetId] = 1;
      }
    });

    return Math.max(...Object.entries(connectionsDct).map(([, value]) => value));
  };

  const hideAllTippy = () => {
    cyto.edges().forEach((ele) => ele.badge?.destroy());
  };

  const goBack = () => {
    hideAllTippy();
    navigate(-1);
  };

  const getNodeIcon = (id) => {
    let type = nodes?.filter((x) => x.data.id === id)[0].data.label;
    return NodeIcons[type].whiteImage;
  };

  useEffect(
    () => () => {
      badgesRef.current.forEach((badge) => {
        badge.badge?.destroy();
      });
      badgesRef.current = [];
    },
    []
  );

  const getNetworkTitle = () => {
    const nodes = network?.nodes;
    if (!nodes || nodes.length === 0) {
      return;
    }

    return `Network - ${capitalize(nodes[0].entity.entity_type)} - ${nodes[0].entity.label}`;
  };

  /*
  const handleLayoutChange = (layout) => {
    setLayout(layout);
    // setIsAllTippyOpen(false);
    hideAllTippy();
    badgesRef.current.forEach((badge) => {
      badge.badge?.destroy();
    });
    badgesRef.current = [];
  };
   */

  const toggleCloseIcon = (event, display) => {
    const btn =
        event.target.tippy?.popper?.getElementsByClassName('close-tip')?.[0];

    if (btn) {
      btn.style.display = display;
    }
  };

  const cytoScapeNodes = () => cy._private.elements.filter((item) => item._private.group === 'nodes');

  const getSourcesOfTarget = (target) => {
    const sources = [];
    const cyNodes = cytoScapeNodes();

    const id = target._private.data.id;
    const relativeEdges = edges.filter((edge) => edge.data.target === id);

    relativeEdges.forEach((item) => {
      const sourceNode = cyNodes.find((n) => n._private.data.id === item.data.source);
      if (sources.find((item) => item._private.data.id === sourceNode._private.data.id)) {
        return;
      }

      sources.push(sourceNode);
    });

    return sources;
  };

  const onZoomIn = (duration = 50) => {
    if (cyto.zoom() >= 4) {
      return;
    }

    cyto.animate(
      {zoom: cyto.zoom() + 0.2, panBy: {x: -100, y: -50}},
      {duration}
    );
  };

  const onZoomOut = (duration = 50) => {
    if (cyto.zoom() <= 0.2) {
      return;
    }

    cyto.animate(
      {zoom: cyto.zoom() - 0.2, panBy: {x: 100, y: 50}},
      {duration}
    );
  };

  const onFitToPage = () => {
    cyto.animate(
      {
        fit: {
          eles: graphRef,
          padding: 100
        }
      },
      {duration: 100}
    );
  };

  useEffect(() => {
    if (!cyto?.animate) {
      return;
    }

    onFitToPage();
  }, [cyto]);

  const getTargetsBySourceId = (id) => {
    const cyNodes = cytoScapeNodes();
    return edges
      .filter((edge) => edge.data.source === id)
      .map((item) => cyNodes.find((node) => node._private.data.id === item.data.target));
  };

  const getCountOfTargets = (id) => {
    const cyNodes = cytoScapeNodes();
    const targets = edges
      .filter((edge) => edge.data.source === id)
      .map((edge) => cyNodes.find((item) => edge.data.target === item._private.data.id));
    return {
      count: targets.length,
      hiddenCount: targets.filter((node) => node.hidden()).length
    };
  };

  const drawGraph = () => {
    const maxConnections = calculateGraphConnectedness();

    cy = cytoscape({
      container: graphRef.current,
      avoidOverlap: true,
      style: cytoscapeStyles(triton_id, maxConnections),
      layout: {
        name: layout,
        rows: 5
      },
      elements: {
        nodes: nodes,
        edges: edges
      }
    });

    const nodeColors = {...NodeIcons};

    cy.nodeHtmlLabel(
      [
        {
          query: '.l1',
          valign: 'bottom',
          halign: 'center',
          valignBox: 'bottom',
          halignBox: 'center',
          tpl: function (data) {
            const nodeLabelClassName = `node-label-${data.id.replaceAll(':', '-')}`;

            const nodes = network?.nodes;
            const node = nodes.find((item) => item.entity.triton_id === data.id);

            return renderNodeLabel(
              data.name,
              node,
              nodeColors[data.label].bgColor,
              `${styles.text} ${styles.nodeTitle} ${nodeLabelClassName}`
            );
          }
        }
      ],
      {enablePointerEvents: true}
    );

    let openTippys = [];

    function makeBadge(ele) {
      return makeNodeBadge(cy, ele, getCountOfTargets(ele._private.data.id).hiddenCount);
    }

    const handleCloseTippy = closeTippy(tippyRef);

    cy.on('mouseover', 'node', function (event) {
      $('html,body').css('cursor', 'pointer');

      const element = event.target;
      const title = element.data()?.name || '';

      if (!openTippys.includes(event.target.tippy?.id)) {
        toggleCloseIcon(event, 'none');
      }
    });

    cy.on('mouseout', 'node', function (event) {
      $('html,body').css('cursor', 'default');
    });

    let timer = 0;
    let delay = 500;
    let prevent = false;

    cy.bind('dblclick', 'node', function (event) {
      clearTimeout(timer);
      prevent = true;

      badgesRef.current = toggleNodeCollapse(
        event,
        [...badgesRef.current],
        getTargetsBySourceId,
        getSourcesOfTarget,
        makeBadge,
        getCountOfTargets
      );
    });

    cy.bind('click', 'node', function (evt) {
      // This prevents click event when a doubleclick event occurs
      // within 200ms delay
      timer = setTimeout(() => {
        if (!prevent && evt.type === 'click') {
          onClickNode(evt.target.id());
        }
        prevent = false;
      }, delay);
    });

    cy.edges().unbind('mouseover');
    cy.edges().bind('mouseover', (event) => {
      makePopper(cy, event.target, getNodeIcon, handleCloseTippy);
    });

    cy.maxZoom(4);
    cy.minZoom(0.2);

    cy.edges().on('dblclick', (event) => {
      clearTimeout(timer);
      prevent = true;

      const prev = [...tippyRef.current].find(
        (badge) => badge._private?.data?.id === event.target?._private.data.id
      );

      if (prev) {
        handleCloseTippy(event.target);
      } else if (event.target.badge && !prev) {
        tippyRef.current = [...tippyRef.current, event.target];
      } else if (!event.target.badge) {
        const badge = makePopper(event.target);
        tippyRef.current = [...tippyRef.current, badge];
      }
    });

    cy.edges().bind('mouseout', (event) => {
      const prev = [...tippyRef.current].find(
        (badge) => badge._private?.data?.id === event.target?._private.data.id
      );

      if (!prev) {
        removeChildNodes(event.target, 'canvas-popup-element');
        event.target.badge.destroy();
      }
    });
    setCyto(cy);
  };

  const onReset = () => {
    setLayout('dagre');
    onFitToPage();
    drawGraph();
  };

  useEffect(() => {
    const handler = debounce(onFitToPage, 250);
    window.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, [cyto]);

  useEffect(() => {
    drawGraph();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [network, layout]);

  const controlButtonOnHold = (action) => (e) => {
    const interval = setInterval(action, 150);
    const onMouseUp = () => {
      clearInterval(interval);
      e.target.removeEventListener('mouseup', onMouseUp);
    };

    e.target.addEventListener('mouseup', onMouseUp);
  };

  const onDownload = () => {
    entityDownload.execute(triton_id).then((responseData) => {
      const url = responseData?.url;
      if (!url) {
        return;
      }

      const downloadLink = document.createElement('a');
      downloadLink.download = detailsData?.label ? `${detailsData.label}.zip` : `${triton_id}.zip`;
      downloadLink.href = url;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      downloadLink.remove();
    });
  };

  return (
    <>
      <Grid
        container
        spacing={5}
        className={styles.container}
      >
        <Box className={styles.header}>
          <Box className={styles.headerStart}>
            <Grid
              onClick={goBack} item xs={12} className={styles.backBtn}
            >
              <BackButtonIcon
                className={styles.backBtnIcon}
                style={{fill: theme.palette.secondary.main}}
              />
              <Typography
                component="span"
                color="secondary"
                className={styles.backBtnText}
              >
                Back
              </Typography>
            </Grid>
            <Typography
              title={getNetworkTitle()}
              variant="h6"
              color="secondary"
              className={styles.networksTitle}
            >
              {getNetworkTitle()}
            </Typography>
            <StyledTooltip
              arrow
              title={
                <Typography className={styles.disclaimerText}>
                  {/* eslint-disable-next-line max-len */}
                  Ownership information is currently being updated. If it is not included in Triton, it is either unavailable through current sources or analysts have yet to complete the chart. To ask about the status of updates, email the C4ADS team at contact@triton.fish.
                </Typography>
              }
              backgroundColor={theme.palette.secondary.main}
              placement="bottom-start"
            >
              <InfoIcon
                style={{fill: theme.palette.secondary.main}}
                className={styles.disclaimerIcon}
              />
            </StyledTooltip>
          </Box>
          <Box className={styles.headerEnd}>
            <Typography
              className={styles.helperText}
              color="secondary"
            >
              Double click an entity to collapse its connections.
            </Typography>
            <LoadingButton
              loading={entityDownload.loading} className={styles.downloadButton}
              style={{borderColor: theme.palette.secondary.main}}
              onClick={onDownload}
            >
              <DownloadIcon
                style={{fill: theme.palette.secondary.main}}
                className={styles.downloadButtonIcon}
              />
            </LoadingButton>
          </Box>
        </Box>
        <div className={styles.networkGraphContainer}>
          <div className={styles.controlsButtons}>
            <ControlButton
              icon={<PlusIcon className={styles.controlButtonIcon}/>}
              onMouseDown={
                controlButtonOnHold(() => {
                  onZoomIn(20);
                })
              }
              onClick={
                () => {
                  onZoomIn(20);
                }
              }
            />
            <ControlButton
              icon={<MinusIcon className={styles.controlButtonIcon}/>}
              onMouseDown={
                controlButtonOnHold(() => {
                  onZoomOut(20);
                })
              }
              onClick={
                () => {
                  onZoomOut(20);
                }
              }
            />
            <ControlButton
              icon={<ResetIcon className={styles.controlButtonIcon}/>}
              onClick={onReset}
            />
          </div>
          <div className={styles.networkGraphWrapper}>
            <div className={styles.graph}>
              <div ref={graphRef} className={styles.graphCanvasContainer}/>
            </div>
          </div>
        </div>
      </Grid>
      <div>&nbsp;</div>
    </>
  );
};

const ControlButton = ({icon, onClick, onMouseDown}) => (
  <div
    onClick={onClick}
    onMouseDown={onMouseDown}
    className={styles.controlButton}
  >
    {icon}
  </div>
);
