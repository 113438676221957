import {Autocomplete, Typography, Box, TextField} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import cn from 'classnames';
import styles from './CustomInput.module.css';

export const CustomAutocompleteInput = ({
  placeholder,
  label,
  value,
  onChange,
  loading,
  options,
  renderOption,
  getOptionLabel,
  className,
  disabled,
  isOptionEqualToValue
}) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="column"
      className={cn(styles.box, disabled && styles.boxDisabled, className)}
    >
      {
        !!label && (
          <Typography
            className={styles.label}
            style={
              {
                color: disabled
                  ? theme.palette.black.alpha30
                  : theme.palette.secondary.secondary200
              }
            }
          >
            {label}
          </Typography>
        )
      }
      <Autocomplete
        id="autocomplete"
        autoComplete={true}
        className={styles.autocomplete}
        options={options}
        renderOption={renderOption}
        loading={loading}
        onChange={onChange}
        getOptionLabel={getOptionLabel}
        value={value}
        isOptionEqualToValue={isOptionEqualToValue}
        renderInput={
          (params) => (
            <TextField
              {...params}
              placeholder={placeholder}
              className={cn(styles.textField, disabled && styles.textFieldDisabled)}
            />
          )
        }
      />
    </Box>
  );
};
