import {useEffect, useMemo, useState} from 'react';
import {Outlet, useNavigate} from 'react-router';
import {useLocation} from 'react-router-dom';
import {Box, Typography} from '@mui/material';
import {Toast} from 'components/Toast/Toast';
import {HttpErrorHandler} from 'components/HttpErrorHandler/HttpErrorHandler';
import {AccountHeader} from './components/AccountHeader/AccountHeader';
import {ProfileContainer} from './components/Admin/components/ProfileContainer/ProfileContainer';
import {Tabs} from './components/Admin/components/Tabs/Tabs';
import {useProfileStore} from 'stores/hooks/useProfileStore';
import {ReactComponent as AccountInfoIcon} from 'assets/icons/icon_user_account.svg';
import {ReactComponent as AdminIcon} from 'assets/icons/icon_user_admin.svg';
import styles from './ProfilePage.module.css';

const tabs = [
  (pathname) => ({
    result: pathname === '/profile',
    pathname: '/profile'
  }),
  (pathname, is_admin) => {
    const defaultPathname = '/profile/admin/users';
    const availablePathNames = ['/profile/admin/users', '/profile/admin/organizations'];

    return {
      result: is_admin && pathname.startsWith('/profile/admin'),
      pathname: availablePathNames.includes(pathname) ? pathname : defaultPathname
    };
  }
];

const tabLinks = ['/profile', '/profile/admin/users'];

export const ProfilePage = () => {
  const location = useLocation();
  const [profile] = useProfileStore();
  const [tabId, setTabId] = useState(0);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastText, setToastText] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (profile.loading) {
      return;
    }

    const is_admin = profile?.user?.is_admin;
    let result;
    let tabId = 0;

    for (let i = 0; i < tabs.length; i++) {
      const checkPathname = tabs[i];
      const currentResult = checkPathname(location.pathname, is_admin);

      if (currentResult?.result) {
        result = currentResult;
        tabId = i;
        break;
      }
    }

    setTabId(tabId);
    if (!result) {
      navigate('/profile', {replace: true});
    }
  }, [location.pathname, profile]);

  const handleTabChange = (event, newValue) => {
    setTabId(newValue);
    navigate(tabLinks[newValue]);
    setToastText(newValue);
  };

  const visibleTabs = useMemo(() => {
    const result = [
      {
        id: 'account-info',
        label: (
          <Box
            className={styles.tab}
            display="flex"
            alignItems="center"
          >
            <AccountInfoIcon/>
            <Typography>Account info</Typography>
          </Box>
        )
      }
    ];

    if (profile?.user?.is_admin) {
      result.push({
        id: 'admin',
        label: (
          <Box
            className={styles.tab}
            display="flex"
            alignItems="center"
          >
            <AdminIcon/>
            <Typography>Admin</Typography>
          </Box>
        )
      });
    }

    return result;
  }, [profile?.user]);

  return (
    <>
      <HttpErrorHandler />
      <ProfileContainer>
        <AccountHeader/>
        <Tabs
          value={tabId}
          onChange={handleTabChange}
          tabs={visibleTabs}
        />
        <Box>
          <Outlet/>
        </Box>
      </ProfileContainer>
      <Toast
        open={toastOpen}
        onClose={() => setToastOpen(false)}
        message={toastText}
      />
    </>
  );
};
