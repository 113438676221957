import {forwardRef} from 'react';
import cn from 'classnames';
import {Box} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import styles from './ModalContainer.module.css';

// eslint-disable-next-line react/display-name
export const ModalContainer = forwardRef(({className, children, ...boxProps}, ref) => {
  const theme = useTheme();

  return (
    <Box
      ref={ref}
      className={cn(styles.container, className)}
      display="flex"
      alignItems="center"
      style={
        {background: theme.palette.white.main}
      }
      {...boxProps}
    >
      {children}
    </Box>
  );
});
