import {useEffect, useState} from 'react';
import {Box} from '@mui/material';
import cn from 'classnames';
import {Loader} from 'components/Loader/Loader';
import styles from './LargeImageLoader.module.css';

export const LargeImageLoader = ({
  imgSrc,
  alt,
  className,
  imgClassName,
  style
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const img = new Image();
    const loadHandler = () => {
      setLoading(false);
    };
    img.addEventListener('load', loadHandler);
    img.src = imgSrc;

    return () => {
      img.removeEventListener('load', loadHandler);
    };
  }, [imgSrc]);

  return (
    <Box style={style} className={cn(styles.imageWrapper, className)}>
      <Loader absolute={true} loading={loading}/>
      {
        !loading && <img className={imgClassName} src={imgSrc} alt={alt}/>
      }
    </Box>
  );
};
