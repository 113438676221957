import {Box, Typography} from '@mui/material';
import {FileError} from '../FileError/FileError';
import styles from './ErrorObj.module.css';

export const ErrorObj = ({error}) => (
  <Box className={styles.fileIndexContainer}>
    <Typography className={styles.fileIndexTitle} color="secondary">
      Row index:
      {' '}
      {error.index}
    </Typography>
    <Box>
      {
        error.errors.map(({pathErrorMessages, path}, index) => (
          <FileError
            key={index}
            pathErrorMessages={pathErrorMessages}
            path={path}
          />
        ))
      }
    </Box>
  </Box>
);
