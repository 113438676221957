import {useState} from 'react';
import {useLocation, useNavigate} from 'react-router';
import * as yup from 'yup';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {Box, Modal, Stack, Typography} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import {useTheme} from '@mui/material/styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {useApi} from 'hooks/useApi';
import {usersService} from 'api/services/users';
import {CustomInput} from 'components/CustomInput/CustomInput';
import {ErrorMessage} from 'components/Messages/ErrorMessage';
import {Loader} from 'components/Loader/Loader';
import styles from './ResetForm.module.css';

const schema = yup
  .object({email: yup.string().email().required()})
  .required();

export const ResetForm = () => {
  const [resetModalOpen, setResetModalOpen] = useState(false);
  const [email, setEmail] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const resetPassword = useApi({
    service: usersService.resetPassword,
    immediate: false
  });
  const theme = useTheme();
  const {
    control,
    handleSubmit,
    formState: {errors},
    setError
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {email: location?.state?.email || ''}
  });

  const onSubmit = async ({email}) => {
    const result = await resetPassword.execute(email);

    if (result instanceof Error) {
      const errorsData = result?.response?.data || {};

      if (errorsData?.email?.length) {
        setError('email', {type: 'custom', message: errorsData?.email[0]});
      } else {
        setError('email', {type: 'custom', message: `User with email address: '${email}' not found.`});
      }
    } else {
      setEmail(email);
      setResetModalOpen(true);
    }
  };

  const handleClose = () => {
    setResetModalOpen(false);
    navigate('/login');
  };

  return (
    <Stack className={styles.loginForm}>
      <Modal
        open={resetModalOpen}
        onClose={handleClose}
      >
        <Box
          style={{background: theme.palette.white.main}}
          className={styles.modalBody}
        >
          <IconButton onClick={handleClose} className={styles.closeBtn}>
            <CloseIcon/>
          </IconButton>
          <CheckCircleOutlineIcon color="secondary" className={styles.successIcon}/>
          <Typography className={styles.modalText} color="secondary">
            The instructions for resetting your password have been sent to
            {' '}
            <span className={styles.currentEmail}>{email}</span>
          </Typography>
        </Box>
      </Modal>
      <Loader loading={resetPassword.loading} absolute={true} />
      <Box className={styles.email}>
        <Controller
          control={control}
          rules={
            {required: true}
          }
          render={
            ({field: {...props}}) => (
              <CustomInput
                label="Email"
                required={true}
                placeholder="Email"
                disabled={resetPassword.loading}
                {...props}
              />
            )
          }
          name="email"
        />
        {!!errors.email && <ErrorMessage message={errors.email.message}/>}
      </Box>
      <LoadingButton
        fullWidth
        onClick={handleSubmit(onSubmit)}
        variant="contained"
        subvariant="fat"
        loadingPosition="center"
        color="red"
        loading={resetPassword.loading}
      >
        <span className={styles.loginLabel} style={{color: theme.palette.white.main}}>
          Reset password
        </span>
      </LoadingButton>
    </Stack>
  );
};
