import React, {useEffect, useMemo, useState} from 'react';
import {useTheme} from '@mui/material/styles';
import {Grid, Typography, Box} from '@mui/material';
import {sortBy} from 'lodash';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import cn from 'classnames';
import {NoMaxWidthTooltip} from 'components/NoMaxWidthTooltip/NoMaxWidthTooltip';
import {useDetailsPanelStore} from 'stores/hooks/useDetailsPanelStore';
import {useApi} from 'hooks/useApi';
import {entitiesService} from 'api/services/entities';
import {MemberChain} from './MemberChain/MemberChain';
import {Scheme} from './Scheme/Scheme';
import {IUUStatus} from './IUUStatus/IUUStatus';
import {
  getChainItems,
  getChainToChild,
  getChainToParent,
  getNode,
  getNodeIndex,
  preparingNodes
} from './ConnectionsUtils';
import {ReactComponent as ConnectionsIcon} from 'assets/icons/icon_connections.svg';
import {ReactComponent as WarningIcon} from 'assets/icons/icon_warning.svg';
import {ReactComponent as SortIcon} from 'assets/icons/icon_sort_gray.svg';
import {ReactComponent as InfoOutlinedIcon} from 'assets/icons/icon_info.svg';
import styles from './Connections.module.css';

export const Connections = ({tritonId: propsTritonId, onMemberId}) => {
  const {mainNode, setMainNode} = useDetailsPanelStore();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedConnection, setSelectedConnection] = useState();
  const [sortField, setSortField] = useState();
  const [connections, setConnections] = useState([]);
  const networkWrapped = useApi({
    service: entitiesService.networkWrapped,
    immediate: false
  });
  const networkWrapped2 = useApi({
    service: entitiesService.networkWrapped,
    immediate: false
  });
  const theme = useTheme();

  useEffect(() => {
    return () => {
      // This will work with the onClose event
      networkWrapped.abort();
      networkWrapped2.abort();
    };
  }, []);

  useEffect(() => {
    setLoading(true);

    // This is necessary in order to interrupt the previous request that is still being executed
    networkWrapped.abort();
    networkWrapped2.abort();

    const promises = Promise.all([
      networkWrapped2.execute({
        root: {triton_id: propsTritonId},
        method: 'ancestors'
      }),
      networkWrapped.execute({
        root: {triton_id: propsTritonId},
        method: 'self_and_descendants'
      })
    ]);

    promises.then((results) => {
      const nodes = results.map((data) => preparingNodes(data?.nodes || []));
      setConnections(nodes.flat(1).filter((node) => node.triton_id !== propsTritonId));

      // dataNodes = [...ancestors, self, ...descendants]
      const dataNodes = results.map((data) => data?.nodes || []).flat(1);
      const dataEdges = results.map((data) => data?.edges || []).flat(1);

      setData({
        nodes: dataNodes,
        edges: dataEdges
      });
      setLoading(false);
    });

    setData({});
    setConnections([]);
  }, [propsTritonId]);

  const listed = useMemo(() => {
    const nodes = data?.nodes || [];

    const node = nodes.find((node) => node.entity.iuu_status === 'listed');

    return node !== undefined;
  }, [data]);

  const getChains = (selectedNode, relatedTritonId) => {
    if (!selectedNode) {
      return [];
    }

    const selectedNodeIndex = getNodeIndex(data?.nodes || [], selectedNode?.triton_id);
    const currentNodeIndex = getNodeIndex(data?.nodes || [], relatedTritonId);

    let newChains = [];
    if (selectedNodeIndex !== -1 && currentNodeIndex !== -1) {
      if (currentNodeIndex > selectedNodeIndex) {
        newChains = getChainToParent(
          selectedNode.triton_id,
          relatedTritonId,
          data?.edges || []
        );
      } else {
        newChains = getChainToChild(
          selectedNode.triton_id,
          relatedTritonId,
          data?.edges || []
        );
      }
    }

    if (newChains.length && !Array.isArray(newChains[0])) {
      newChains = [newChains];
    }

    return newChains.map((chain) => getChainItems(chain, data?.nodes || []));
  };

  const getNodeChains = (selectedNode) => {
    const triton_id = selectedNode?.triton_id === mainNode ? propsTritonId : mainNode || propsTritonId;

    let result = getChains(
      selectedNode,
      triton_id
    );

    if (result.length === 0) {
      result = getChains(
        selectedNode,
        propsTritonId
      );
    }

    return result;
  };

  const getRelation = (node) => {
    const edges = data?.edges || [];

    const asTarget = edges.find((edge) => edge.target === node.triton_id && edge.source === propsTritonId);
    const asSource = edges.find((edge) => edge.source === node.triton_id && edge.target === propsTritonId);

    const chains = getNodeChains(node);
    if (asTarget) {
      return {
        label: asTarget?.relation_type || 'N/A',
        tooltip: {chains}
      };
    } else if (asSource) {
      return {
        label: asSource.relation_type || 'N/A',
        tooltip: {chains}
      };
    } else {
      return {
        label: 'Non-direct relationship',
        tooltip: {chains}
      };
    }
  };

  const chains = useMemo(() => {
    return getNodeChains(selectedConnection);
  }, [
    data,
    selectedConnection,
    mainNode,
    propsTritonId
  ]);

  const handleMemberOnClick = (currentChains, member, index = 0) => {
    const chain = currentChains[index];

    setMainNode(chain?.[0]?.node?.entity?.triton_id);
    setSelectedConnection(getNode(data?.nodes, chain?.[0]?.node?.entity?.triton_id));
    onMemberId(member.triton_id);
  };

  const connectionOnClick = (connection) => () => {
    setSelectedConnection(selectedConnection?.triton_id === connection.triton_id ? undefined : connection);

    const chains = getNodeChains(connection);
    handleMemberOnClick(chains, connection, 0);
  };

  const sortedConnections = useMemo(() => {
    const customIUUStatuses = {
      'listed': 1,
      'delisted': 2,
      'neverListed': 3
    };
    const customSchemes = {
      person: 1,
      vessel: 2,
      company: 3
    };
    return sortBy(connections.map((connection) => ({
      ...connection,
      customIUUStatus: customIUUStatuses[connection.iuuStatus] || 3,
      customScheme: customSchemes[connection.scheme] || 3
    })), [sortField ? [sortField] : []]);
  }, [sortField, connections]);

  const onSort = (field) => {
    return () => {
      setSortField((prevField) => prevField === field ? undefined : field);
    };
  };

  return (
    <Grid container className={styles.container}>
      <Grid item xs={12}>
        <Box display="flex" flexDirection="column" className={styles.chains} >
          {
            !!selectedConnection && chains.map((chain, index) => (
              <MemberChain
                key={index}
                onMember={(member) => {
                  handleMemberOnClick(chains, member, index);
                }}
                chain={chain}
                tritonId={propsTritonId}
              />
            ))
          }
        </Box>
      </Grid>
      {
        !!loading && (
          <Box
            className={styles.loadingBlock}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress color="inProgress"/>
            <Typography color="secondary">Loading...</Typography>
          </Box>
        )
      }
      {
        connections.length > 0 && (
          <>
            <Grid item xs={12}>
              <Box
                className={styles.connectionsInfo}
                display="flex"
                alignItems="center"
              >
                <ConnectionsIcon/>
                <Typography
                  style={{color: theme.palette.red.main}}
                  className={styles.connectionsInfoTitle}
                >
                  Connections
                  {' '}
                  {connections.length}
                </Typography>
                {
                  !!listed && <WarningIcon/>
                }
              </Box>
            </Grid>
            <Grid item xs={12} className={styles.connectionsContainer}>
              <Box
                className={styles.connectionsHeader}
                width="100%"
                display="flex"
                alignItems="flex-end"
                justifyContent="space-between"
                style={
                  {backgroundColor: theme.palette.red.alpha10}
                }
              >
                <Box
                  className={styles.tab}
                  display="flex"
                  alignItems="center"
                  style={
                    {
                      color: theme.palette.red.main,
                      borderColor: theme.palette.red.main
                    }
                  }
                >
                  <Typography className={styles.tabTitle}>Membership</Typography>
                  <span className={styles.dot}/>
                  <Typography className={styles.tabTitleSubData}>{connections.length}</Typography>
                </Box>
              </Box>
              <Grid
                container
                className={styles.connectionsTable}
                style={{borderColor: theme.palette.gray.gray100}}
              >
                <Grid className={styles.connectionsTableBody}>
                  <SortableHeaderCell
                    onClick={onSort('customScheme')}
                    label="Entity Type"
                    active={sortField === 'customScheme'}
                  />
                  <SortableHeaderCell
                    onClick={onSort('name')}
                    label="Name"
                    active={sortField === 'name'}
                  />
                  <SortableHeaderCell
                    className={styles.iuuStatusHeader}
                    onClick={onSort('customIUUStatus')}
                    label="IUU Status"
                    active={sortField === 'customIUUStatus'}
                  />
                  <div className={styles.connectionsTableHeaderCell}>
                    <Typography className={styles.headerTitle} color="secondary">
                      Relationship
                    </Typography>
                  </div>
                  {
                    sortedConnections.map((connection, index) => {
                      const relation = getRelation(connection);

                      return (
                        <React.Fragment key={connection.triton_id}>
                          <div
                            onClick={connectionOnClick(connection)}
                            className={
                              cn(
                                styles.connectionsTableCell,
                                styles.entityTypeBlock,
                                connection.triton_id === selectedConnection?.triton_id && styles.selectedConnection,
                                index + 1 === connections.length && styles.connectionsTableLastRowCell
                              )
                            }
                          >
                            <Scheme
                              active={selectedConnection?.triton_id === connection.triton_id}
                              scheme={connection.scheme}
                              onClick={connectionOnClick(connection)}
                            />
                            <Typography
                              className={styles.entityType}
                              style={
                                {color: theme.palette.gray.gray900}
                              }
                              color="secondary"
                            >
                              {connection.scheme}
                            </Typography>
                          </div>
                          <div
                            onClick={connectionOnClick(connection)}
                            className={
                              cn(
                                styles.connectionsTableCell,
                                connection.triton_id === selectedConnection?.triton_id && styles.selectedConnection,
                                index + 1 === connections.length && styles.connectionsTableLastRowCell
                              )
                            }
                          >
                            <Typography
                              className={styles.connectionsTableRowText}
                              style={
                                {color: theme.palette.gray.gray900}
                              }
                              title={connection.name}
                            >
                              {connection.name}
                            </Typography>
                          </div>
                          <div
                            onClick={connectionOnClick(connection)}
                            className={
                              cn(
                                styles.connectionsTableCell,
                                connection.triton_id === selectedConnection?.triton_id && styles.selectedConnection,
                                index + 1 === connections.length && styles.connectionsTableLastRowCell,
                                styles.iuuStatusBlock
                              )
                            }
                          >
                            <IUUStatus
                              connection={connection}
                              edges={data?.edges || []}
                              nodes={data?.nodes || []}
                            />
                          </div>
                          <div
                            onClick={connectionOnClick(connection)}
                            className={
                              cn(
                                styles.connectionsTableCell,
                                connection.triton_id === selectedConnection?.triton_id && styles.selectedConnection,
                                index + 1 === connections.length && styles.connectionsTableLastRowCell
                              )
                            }
                          >
                            <NoMaxWidthTooltip
                              arrow
                              className={styles.relationTooltip}
                              backgroundColor={theme.palette.secondary.main}
                              title={
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  className={styles.relationInnerBlock}
                                >
                                  {relation.tooltip.chains.map((chain, index) => (
                                    <MemberChain
                                      key={index}
                                      onMember={(member) => {
                                        handleMemberOnClick(member, index);
                                      }}
                                      chain={chain}
                                      tritonId={propsTritonId}
                                      white
                                    />
                                  ))}
                                </Box>
                              }
                              placement="top"
                              enterDelay={500}
                            >
                              <Box
                                display="flex"
                                alignItems="center"
                                className={styles.relationBlock}
                              >
                                <InfoOutlinedIcon
                                  style={
                                    {fill: theme.palette.gray.gray900}
                                  }
                                  className={styles.relationIcon}
                                />
                                <Typography
                                  title={relation.label}
                                  className={styles.connectionsTableRowText}
                                  style={
                                    {color: theme.palette.gray.gray900}
                                  }
                                >
                                  {relation.label}
                                </Typography>
                              </Box>
                            </NoMaxWidthTooltip>
                          </div>
                        </React.Fragment>
                      );
                    })
                  }
                </Grid>
              </Grid>
            </Grid>
            <Typography
              style={{color: theme.palette.gray.gray200}}
              className={styles.disclaimer}
            >
              {/* eslint-disable-next-line max-len */}
              Ownership information is currently being updated. If it is not included in Triton, it is either unavailable through current sources or analysts have yet to complete the chart. To ask about the status of updates, email the C4ADS team at contact@triton.fish.
            </Typography>
          </>
        )
      }
    </Grid>
  );
};

const SortableHeaderCell = ({label, className, active, onClick}) => {
  return (
    <div
      onClick={onClick}
      className={cn(
        styles.connectionsTableHeaderCell,
        styles.sortableConnectionsTableHeaderCell,
        className
      )}
    >
      <Typography className={styles.headerTitle} color="secondary" textAlign="center">
        {label}
      </Typography>
      <SortIcon className={cn(styles.headerSortIcon, active && styles.headerSortIconActive)}/>
    </div>
  );
};
