import {useEffect} from 'react';
import {closeSnackbar, enqueueSnackbar} from 'notistack';
import {useNavigate} from 'react-router';
import {Button} from '@mui/material';
import {useHttpErrorStore} from 'stores/hooks/useHttpErrorStore';
import styles from './HttpErrorHandler.module.css';

export const HttpErrorHandler = () => {
  const {httpErrorLighthouse} = useHttpErrorStore();
  const navigate = useNavigate();

  const getVariant = (status) => {
    if (status >= 400 && status < 500) {
      return 'warning';
    } else if (status >= 500) {
      return 'error';
    } else {
      return 'default';
    }
  };

  useEffect(() => {
    const subscriber = httpErrorLighthouse.listen((httpStatus, message) => {
      const config = {
        preventDuplicate: true,
        key: httpStatus,
        persist: httpStatus === 401,
        autoHideDuration: 2500,
        variant: getVariant(httpStatus),
        action: httpStatus === 401 && ((snackbarId) => (
          <Button
            className={styles.loginButton} variant="outlined" size="small" onClick={
              () => {
                closeSnackbar(snackbarId);
                navigate('/login', {state: {redirectUrl: window.location.pathname + window.location.search}});
              }
            }
          >
            Login
          </Button>
        ))
      };
      enqueueSnackbar(message, config);
    });

    return subscriber.stopListen;
  }, []);

  return null;
};
