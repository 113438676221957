export const COMPANY_DETAIL_FIELDS = [
  /*
  {
    key: 'names',
    label: 'Name'
  },
   */
  {
    key: 'akas',
    label: 'Also Known As'
  },
  {
    key: 'phones',
    label: 'Phone Number'
  },
  {
    key: 'informationDates',
    label: 'Date of Information',
    format: 'datetime'
  },
  {
    key: 'addresses',
    label: 'Address'
  },
  {
    key: 'countries',
    label: 'Country of Registration'
  },
  {
    key: 'incorporationDates',
    label: 'Date of Incorporation',
    format: 'datetime'
  },
  {
    key: 'emails',
    label: 'Email Address'
  },
  {
    key: 'formerNames',
    label: 'Former Names'
  },
  {
    key: 'taxIds',
    label: 'Tax or Registration ID'
  },
  {
    key: 'websites',
    label: 'Website'
  }
];

export const PERSON_DETAIL_FIELDS = [
  /*
  {
    key: 'names',
    label: 'Name'
  },
   */
  {
    key: 'akas',
    label: 'Also Known As'
  },
  {
    key: 'phones',
    label: 'Phone Number'
  },
  {
    key: 'birthDates',
    label: 'Date of Birth',
    format: 'datetime'
  },
  {
    key: 'informationDates',
    label: 'Date of Information',
    format: 'datetime'
  },
  {
    key: 'addresses',
    label: 'Address'
  },
  {
    key: 'nationalities',
    label: 'Nationality'
  },
  {
    key: 'taxIds',
    label: 'Tax ID'
  },
  {
    key: 'emails',
    label: 'Email Address'
  },
  {
    key: 'countries',
    label: 'Affiliated Country'
  }
];

export const VESSEL_DETAIL_FIELDS = [
  /*
  {
    key: 'names',
    label: 'Name'
  },
   */
  {
    key: 'flags',
    label: 'Flag'
  },
  {
    key: 'sanction_status',
    label: 'Sanction Status'
  },
  {
    key: 'iuu_status',
    label: 'IUU Status'
  },
  {
    key: 'informationDates',
    label: 'Date of Information',
    format: 'datetime'
  },
  {
    key: 'imo_id',
    label: 'IMO'
  },
  {
    key: 'callsigns',
    label: 'Callsign'
  },
  {
    key: 'grossTonnages',
    label: 'Gross Tonnage',
    format: 'number'
  },
  {
    key: 'iuuStatusDescriptions',
    label: 'IUU Status Details'
  },
  {
    key: 'sources',
    label: 'Regional Fishery Body Registrations'
  },
  {
    key: 'mmsiIds',
    label: 'MMSI'
  },
  {
    key: 'lengths',
    label: 'Length',
    format: 'number'
  },
  {
    key: 'statuses',
    label: 'Status'
  },
  {
    key: 'formerFlags',
    label: 'Former Flag'
  },
  {
    key: 'builtYears',
    label: 'Year Built'
  },
  {
    key: 'fleet_tags',
    label: 'Gear Type'
  }
];

export const RFMO_SOURCES = [
  'CCSBT',
  'FFA',
  'GFCM',
  'IATTC',
  'ICCAT',
  'IOTC',
  'NAFO',
  'NPFC',
  'SEAFO',
  'SIOFA',
  'SPRFMO',
  'WCPFC'
];

export const SEARCH_FILTERS = {
  textFilters: {
    name: '',
    imoId: '',
    sources: []
  },
  paginationFilters: {
    page: 1,
    page_size: 25,
    ordering: '-'
  },
  typeFilters: {
    vessel: false,
    company: false,
    person: false
  },
  statusFilters: {
    listed: false,
    delisted: false,
    neverListed: false
  },
  fleetFilters: {
    reefer: false,
    longline: false,
    gillnets: false,
    purse_seine: false,
    jigger: false,
    trawler: false,
    unknown: false
  },
  sanctionFilters: {
    active_sanctions: false,
    past_sanctions: false,
    no_information: false
  },
  countries: []
};

export const NETWORK_FILTERS = {root: null};

export const TEXT_FILTERS = {
  name: '',
  imoId: ''
};

export const TYPE_FILTERS = {
  vessel: false,
  company: false,
  person: false
};

export const STATUS_FILTERS = {
  listed: false,
  delisted: false,
  neverListed: false
};

export const FLEET_FILTERS = {
  reefer: false,
  longline: false,
  gillnets: false,
  purse_seine: false,
  jigger: false,
  trawler: false,
  unknown: false
};

export const SANCTION_FILTERS = {
  active_sanctions: false,
  past_sanctions: false,
  no_information: false
};

export const SANCTION_STATUS_DCT = {
  active_sanctions: 'error',
  past_sanctions: 'warning',
  no_information: 'default'
};

export const UUI_STATUS_DCT = {
  'listed': 'error',
  'delisted': 'default'
};
