import {useEffect, useState, useRef, createContext} from 'react';
import {useNavigate} from 'react-router';
import Grid from '@mui/material/Grid';
import TuneIcon from '@mui/icons-material/Tune';
import IconButton from '@mui/material/IconButton';
import cn from 'classnames';
import {entitiesService} from 'api/services/entities';
import {useApi} from 'hooks/useApi';
import {useSearchStore} from 'stores/hooks/useSearchStore';
import {useLayoutStore} from 'stores/hooks/useLayoutStore';
import {keydownListener} from 'utils/helpers';
import {useBroadcaster} from 'hooks/useBroadcaster';
import {SearchInput} from './components/SearchFilters/SearchInput/SearchInput';
import {SearchFilters} from './components/SearchFilters/SearchFilters';
import {SearchResults} from './components/SearchResults/SearchResults';
import {DetailsPanel} from 'components/DetailsPanel/DetailsPanel';
import {HttpErrorHandler} from 'components/HttpErrorHandler/HttpErrorHandler';
import {Loader} from 'components/Loader/Loader';
import styles from './SearchPage.module.css';

export const SearchContext = createContext({});
export const SEARCH_PAGE_BROADCASTER = 'searchPageBroadcaster';

export const SearchPage = () => {
  const navigate = useNavigate();
  const {
    injectComponentToHeader,
    clearHeader,
    showFooter,
    hideFooter,
    setLayoutClassName
  } = useLayoutStore();
  const [loading, setLoading] = useState(false);
  const [openSearchFilters, setOpenSearchFilters] = useState(false);
  const [detailsPanelOpen, setDetailsPanelOpen] = useState(false);
  const searchResultsGridRef = useRef(null);
  const searchFiltersRef = useRef({});
  const [searchSubscriber, searchBroadcaster] = useBroadcaster(SEARCH_PAGE_BROADCASTER);
  const {
    reset,
    execute,
    setPaginationFilter,
    searchFilters,
    updateFilter,
    updateRow
  } = useSearchStore();

  const details = useApi({
    service: entitiesService.details,
    immediate: false
  });

  useEffect(() => {
    hideFooter();

    return showFooter;
  }, []);

  useEffect(() => {
    setLayoutClassName(styles.searchPageLayout);

    return () => {
      setLayoutClassName(undefined);
    };
  }, []);

  useEffect(() => {
    injectComponentToHeader(
      <div className={styles.searchInputContainer}>
        <SearchInput
          onSubmit={() => {
            searchFiltersRef.current.apply();
          }}
          defaultValue={searchFilters.textFilters.name}
          onSearch={(name) => {
            updateFilter('textFilters', {
              ...searchFilters.textFilters,
              name
            });
          }}
        />
      </div>
    );

    return clearHeader;
  }, [searchFilters]);

  useEffect(() => {
    return keydownListener('Enter', execute);
  }, [execute]);

  useEffect(() => {
    if (!searchSubscriber) {
      return;
    }

    return searchSubscriber.on('data', (data) => {
      /*
        type: 'isFavorite', isFavorite: boolean, tritonId: string
       */
      if (data.type === 'isFavorite') {
        updateRow(data.tritonId, {is_favorite: data.isFavorite});
      }
    });
  }, [searchSubscriber]);

  const handleClickSearch = (event) => {
    if (event) {
      event.preventDefault();
    }
    execute();
  };

  const onClosePanel = () => {
    setDetailsPanelOpen(false);
  };

  const viewNetworksHandleClick = (tritonId) => {
    navigate(`/networks?triton_id=${tritonId}`);
  };

  const onCellClick = async (tritonId) => {
    setLoading(true);
    const result = await details.execute(tritonId);
    setLoading(false);
    if (result instanceof Error) {
      return;
    }

    setDetailsPanelOpen(true);
  };

  const onPagination = (pagination) => {
    setPaginationFilter(pagination);
  };

  const toggleSearchFilters = () => {
    setOpenSearchFilters((prev) => !prev);
  };

  return (
    <>
      <Loader loading={loading}/>
      <HttpErrorHandler/>
      <SearchContext.Provider value={
        {updateRow}
      }
      >
        <Grid
          container
          className={styles.container}
        >
          <Grid
            item
            xs={12}
            lg={4}
            className={
              cn(
                styles.searchFiltersContainer,
                openSearchFilters && styles.searchFiltersContainerOpen
              )
            }
          >
            <IconButton
              className={styles.drawerButton}
              variant="contained"
              color="secondary"
              onClick={toggleSearchFilters}
            >
              <TuneIcon/>
            </IconButton>
            <SearchFilters
              ref={searchFiltersRef}
              onPagination={onPagination}
              onReset={reset}
              onClickSearch={handleClickSearch}
              onClose={
                () => {
                  setOpenSearchFilters(false);
                }
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={8}
            ref={searchResultsGridRef}
            className={styles.searchResults}
          >
            <SearchResults
              className={styles.searchResultsInner}
              onPagination={onPagination}
              viewNetworksHandleClick={viewNetworksHandleClick}
              onClickCell={onCellClick}
              parentContainerRef={searchResultsGridRef}
            />
          </Grid>
        </Grid>
        <DetailsPanel
          broadcaster={searchBroadcaster}
          data={details.data}
          open={detailsPanelOpen}
          onClose={onClosePanel}
          viewNetworksHandleClick={viewNetworksHandleClick}
          showNetworkButton={true}
        />
      </SearchContext.Provider>
    </>
  );
};
