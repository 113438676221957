import {useMemo, useState} from 'react';
import {useTheme} from '@mui/material/styles';
import {Box} from '@mui/material';
import {useAdminStore} from 'stores/hooks/useAdminStore';
import {DataTable} from 'components/DataTable/DataTable';
import {ColumnHeader} from 'components/DataTable/components/ColumnHeader/ColumnHeader';
import {TableCell} from '../../../components/Table/TableCell/TableCell';
import {SectionHeader} from '../../../components/SectionHeader/SectionHeader';

export const MultipleUsersPreview = ({users}) => {
  const {organizations} = useAdminStore();
  const theme = useTheme();
  const rows = useMemo(() => users.map((user, index) => ({...user, id: index})), [users]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(rows.length > 100 ? 100 : rows.length);

  const getOrganizationName = (organizationId) => {
    if (!organizationId) {
      return '';
    }

    return organizations?.data?.find((organization) => organization.id === organizationId)?.name || '';
  };

  const columns = useMemo(() => [
    {
      field: 'email',
      renderHeader: () => <ColumnHeader label="Email"/>,
      disableColumnMenu: true,
      minWidth: 250,
      flex: 1,
      renderCell: ({value}) => (
        <TableCell label={value} color="secondary"/>
      )
    },
    {
      field: 'name',
      renderHeader: () => <ColumnHeader label="Name"/>,
      disableColumnMenu: true,
      minWidth: 150,
      renderCell: ({row}) => {
        const nameParts = [row?.first_name, row?.last_name];

        return (
          <TableCell label={nameParts.filter(Boolean).join(' ')} color="secondary"/>
        );
      }
    },
    {
      field: 'organization_id',
      renderHeader: () => <ColumnHeader label="Organization"/>,
      disableColumnMenu: true,
      minWidth: 220,
      renderCell: ({value}) => (
        <TableCell label={getOrganizationName(value) || '-'} color="secondary"/>
      )
    },
    {
      field: 'is_staff',
      renderHeader: () => <ColumnHeader label="Role"/>,
      disableColumnMenu: true,
      minWidth: 100,
      sortable: false,
      renderCell: ({value: is_staff}) => (
        <TableCell
          label={is_staff ? 'Admin' : 'User'}
          color="secondary"
          filled={true}
          style={
            {
              backgroundColor: is_staff
                ? theme.palette.red.alpha10
                : theme.palette.secondary.alpha10
            }
          }
        />
      )
    }
  ], [organizations.data]);

  return (
    <Box width="100%">
      <SectionHeader line={false} unboxed={true} title="Users"/>
      <Box
        height={240} width="100%" style={rows.length > 100 ? {marginBottom: '32px'} : undefined}
      >
        <DataTable
          columns={columns}
          rows={rows}
          minHeight={200}
          maxHeight={240}
          autoHeight={true}
          dataGridProps={
            {
              getRowId: (row) => row.id,
              loading: false,
              sortingMode: 'client',
              pagination: true,
              paginationMode: 'client',
              rowCount: rows.length,
              page,
              hideFooterPagination: rows.length < 100,
              pageSize,
              onPageChange: setPage,
              onPageSizeChange: (newPageSize) => {
                setPageSize(newPageSize);
              }
            }
          }
          dataGridStyles={
            {
              '& .MuiDataGrid-columnHeaders': {border: 'none'},
              '& .MuiDataGrid-virtualScroller': {
                maxHeight: '200px',
                overflowY: 'auto !important'
              }
            }
          }
          emptyOverlayProps={{text: 'There are currently no users.'}}
        />
      </Box>
    </Box>
  );
};
