import {createContext} from 'react';

class Subscriber {
  constructor() {
    this.active = false;
    this.callbacks = {};
  }

  push(eventName, data) {
    if (!this.active) {
      return;
    }

    const callbacks = this.callbacks[eventName];
    if (!callbacks) {
      return;
    }

    Object.entries(callbacks).forEach(([, callback]) => callback(data));
  }

  stopListen() {
    this.active = false;
  }

  startListen() {
    this.active = true;
  }

  on(eventName, callback) {
    const callbackId = !this.callbacks[eventName]
      ? '0'
      : String(Object.keys(this.callbacks[eventName]).length);

    if (callbackId === '0') {
      this.callbacks[eventName] = {};
    }
    this.callbacks[eventName][callbackId] = callback;

    return () => {
      delete this.callbacks[eventName][callbackId];
    };
  }
}

class IndexSubscriber extends Subscriber {
  constructor() {
    super();
    this.index = true;
  }
}

class Broadcaster {
  constructor() {
    this.subscribers = {};
  }

  createSubscriber(prefix) {
    const id = String(new Date().getTime());
    const subscriberId = prefix ? prefix + id : id;
    const subscriber = new Subscriber();
    subscriber.startListen();

    this.subscribers[subscriberId] = subscriber;

    return [
      subscriber,
      () => {
        subscriber.stopListen();
        delete this.subscribers[subscriberId];
      }
    ];
  }

  sendEvent(eventName, data, exceptIndex) {
    Object.keys(this.subscribers).forEach((subscriberId) => {
      const subscriber = this.subscribers[subscriberId];
      if (exceptIndex && subscriber.index) {
        return;
      }
      subscriber.push(eventName, data);
    });
  }

  update(exceptIndex = false) {
    this.sendEvent('update', exceptIndex);
  }

  sendData(data, exceptIndex = false) {
    this.sendEvent('data', data, exceptIndex);
  }
}

class UpdateService {
  constructor() {
    this.broadcasters = {};
  }

  registerBroadcaster(name) {
    const destroyBroadcaster = () => {
      delete this.broadcasters[name];
    };

    if (this.broadcasters[name]) {
      return [this.broadcasters[name].subscribers['0'], destroyBroadcaster];
    }

    const broadcaster = new Broadcaster();
    this.broadcasters[name] = broadcaster;

    const indexSubscriber = new IndexSubscriber();
    broadcaster.subscribers['0'] = indexSubscriber;
    indexSubscriber.startListen();

    return [indexSubscriber, destroyBroadcaster];
  }
}

export const updateService = new UpdateService();
export const UpdateServiceContext = createContext({updateService});

export const UpdateServiceStore = (props) => {
  const {children} = props;

  return (
    <UpdateServiceContext.Provider value={{updateService}}>
      {children}
    </UpdateServiceContext.Provider>
  );
};
