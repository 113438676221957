import {BulkCreation} from '../../../Users/AddUser/BulkCreation';

export const InviteUsers = ({
  onUsers,
  organization,
  onHideModal,
  onShowModal
}) => (
  <BulkCreation
    onUsers={onUsers}
    organizationId={organization.id}
    onHideModal={onHideModal}
    onShowModal={onShowModal}
  />
);

