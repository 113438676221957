import cn from 'classnames';
import {useTheme} from '@mui/material/styles';
import {Grid, Typography} from '@mui/material';
import styles from '../SearchResultsTable.module.css';

export const ColumnHeader = ({label, className, endAddon, justifyContent}) => {
  const theme = useTheme();

  return (
    <Grid
      justifyContent={justifyContent}
      className={cn(styles.labelContainer, className)}
      container
      wrap="nowrap"
      alignItems="center"
    >
      <Typography
        className={styles.tableLabel}
        variant="body2"
        color={theme.palette.gray.gray300}
      >
        {label}
      </Typography>
      {endAddon}
    </Grid>
  );
};
