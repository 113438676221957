import {Box, Typography} from '@mui/material';
import styles from './FileError.module.css';

export const FileError = ({pathErrorMessages, path}) => (
  <Box className={styles.fieldContainer}>
    <Typography className={styles.fieldTitle} color="secondary">
      {path}
      :
    </Typography>
    <Box className={styles.fieldErrorsContainer}>
      {
        pathErrorMessages.map((message, index) => (
          <Typography
            className={styles.fieldErrorMessage}
            key={index} color="error"
          >
            {message}
          </Typography>
        ))
      }
    </Box>
  </Box>
);
