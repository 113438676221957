import {createContext, useReducer} from 'react';

const initialState = {newRequestOpen: false};

const reducer = (state, action) => {
  switch (action.type) {
  case 'updateNewRequestOpen':
    return {...state, newRequestOpen: action.newRequestOpen};
  default:
    return state;
  }
};

export const SupportRequestContext = createContext(initialState);

export const SupportRequestStore = (props) => {
  const {children} = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <SupportRequestContext.Provider value={
      [state, dispatch]
    }
    >
      {children}
    </SupportRequestContext.Provider>
  );
};
