import {createBrowserHistory} from 'history';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {ThemeProvider} from '@mui/material/styles';
import {SnackbarProvider} from 'notistack';
import {QueryParamProvider} from 'use-query-params';
import {ReactRouter6Adapter} from 'use-query-params/adapters/react-router-6';
import createCache from '@emotion/cache';
import {CacheProvider} from '@emotion/react';

import {ProtectedRoute} from './components/ProtectedRoute/ProtectedRoute';
import {Layout} from './components/Layout/Layout';

import {Stores} from 'stores/Stores';
import {Auth0ProviderWithNavigate} from 'stores/contexts/Auth0Context';
import {theme} from 'utils/theme';

import {LoginPage} from 'views/Login/LoginPage';
import {CallbackPage} from 'views/Callback/CallbackPage';
import {ResetPage} from 'views/Reset/ResetPage';
import {ProfilePage} from 'views/Profile/ProfilePage';
import {HomePage} from 'views/Home/HomePage';
import {SearchPage} from 'views/Search/SearchPage';
import {NetworksPage} from 'views/Networks/NetworksPage';
import {NotFoundPage} from 'views/NotFound/NotFoundPage';
import {MethodologyPage} from 'views/Methodology/MethodologyPage';

import {AccountInfo} from './views/Profile/components/AccountInfo/AccountInfo';
import {Admin} from './views/Profile/components/Admin/Admin';
import {Users} from './views/Profile/components/Admin/Users/Users';
import {Organizations} from './views/Profile/components/Admin/Organizations/Organizations';
import {Eula} from './views/Eula/Eula';

import {SearchStoreLayout} from './stores/contexts/SearchStore';

const cache = createCache({
  key: 'css',
  prepend: true
});

export const App = () => {
  const browserHistory = createBrowserHistory();

  return (
    <SnackbarProvider preventDuplicate>
      <CacheProvider value={cache}>
        <ThemeProvider theme={theme}>
          <BrowserRouter history={browserHistory}>
            <Auth0ProviderWithNavigate>
              <Stores>
                <QueryParamProvider
                  adapter={ReactRouter6Adapter}
                  options={{removeDefaultsFromUrl: true}}
                >
                  <Routes>
                    <Route
                      path="/login"
                      element={
                        <ProtectedRoute routeType="public">
                          <LoginPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/reset-password"
                      element={
                        <ProtectedRoute routeType="private">
                          <ResetPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/callback"
                      element={
                        <ProtectedRoute routeType="public">
                          <CallbackPage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/eula"
                      element={
                        <ProtectedRoute routeType="eula">
                          <Eula />
                        </ProtectedRoute>
                      }
                    />
                    <Route element={<Layout />}>
                      <Route
                        path="/methodology"
                        element={
                          <ProtectedRoute routeType="public">
                            <MethodologyPage />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/profile"
                        element={
                          <ProtectedRoute routeType="private">
                            <ProfilePage />
                          </ProtectedRoute>
                        }
                      >
                        <Route
                          path=""
                          element={
                            <ProtectedRoute routeType="private">
                              <AccountInfo />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="admin"
                          element={
                            <ProtectedRoute routeType="private">
                              <Admin />
                            </ProtectedRoute>
                          }
                        >
                          <Route path="users" element={<Users />} />
                          <Route
                            path="organizations"
                            element={<Organizations />}
                          />
                        </Route>
                      </Route>
                      <Route element={<SearchStoreLayout />}>
                        <Route
                          path="/"
                          element={
                            <ProtectedRoute routeType="private">
                              <HomePage />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/search"
                          element={
                            <ProtectedRoute routeType="private">
                              <SearchPage />
                            </ProtectedRoute>
                          }
                        />
                      </Route>
                      <Route
                        path="/networks"
                        element={
                          <ProtectedRoute routeType="private">
                            <NetworksPage />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="*"
                        element={
                          <ProtectedRoute routeType="missing">
                            <NotFoundPage />
                          </ProtectedRoute>
                        }
                      />
                    </Route>
                  </Routes>
                </QueryParamProvider>
              </Stores>
            </Auth0ProviderWithNavigate>
          </BrowserRouter>
        </ThemeProvider>
      </CacheProvider>
    </SnackbarProvider>
  );
};
