import {LoginForm} from './components/LoginForm/LoginForm';
import {AuthWrapper} from 'components/AuthWrapper/AuthWrapper';
import {ReactComponent as LoginTritonLogo} from 'assets/icons/icon_login_triton_logo.svg';
import LoginBackground from 'assets/backgrounds/background_login.jpeg';
import styles from './LoginPage.module.css';

export const LoginPage = () => (
  <AuthWrapper alt="login background" imageSrc={LoginBackground} >
    <LoginTritonLogo className={styles.loginLogo}/>
    <LoginForm/>
  </AuthWrapper>
);
