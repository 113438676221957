import {
  Box,
  Tab as AntdTab,
  Tabs as AntdTabs,
  Typography
} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import styles from './Tabs.module.css';

export const Tabs = ({value, onChange, tabs}) => {
  // tabs -> [{id, label}, ...]
  const theme = useTheme();

  return (
    <AntdTabs
      className={styles.tabs}
      indicatorColor="secondary"
      textColor="secondary"
      value={value}
      onChange={onChange}
      style={
        {borderColor: theme.palette.gray.gray100}
      }
    >
      {
        tabs.map(({id, label}) => (
          <AntdTab
            key={id}
            id={id}
            label={
              typeof label === 'string'
                ? (
                  <Box
                    className={styles.tab}
                    display="flex"
                    alignItems="center"
                  >
                    <Typography>{label}</Typography>
                  </Box>
                )
                : label
            }
          />
        ))
      }
    </AntdTabs>
  );
};
