import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export const Toast = ({open, onClose, message}) => {

  const handleClose = (event, reason) => {
    if (reason !== 'clickaway') {
      onClose();
    }
  };

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      autoHideDuration={3000}
    >
      <Alert onClose={onClose} severity="success">
        {message}
      </Alert>
    </Snackbar>
  );
};
