import {useEffect, useReducer} from 'react';

const initialState = {
  value: '',
  error: false,
  helper: null
};

const reducer = (state, action) => {
  switch (action.type) {
  case 'updateValue':
    return {...state, value: action.value};
  case 'updateError':
    return {...state, error: action.error};
  case 'updateHelper':
    return {...state, helper: action.helper};
  default:
    return state;
  }
};

export function useTextInput({value = null} = {}) {
  const [input, setInput] = useReducer(reducer, initialState);

  useEffect(() => {
    if (value) {
      setInput({type: 'updateValue', value});
    }
  }, []);

  const changeInput = (event) => {
    const value = event.target ? event.target.value : event;
    setInput({type: 'updateValue', value});
    setInput({type: 'updateError', error: false});
    setInput({type: 'updateHelper', helper: null});
  };

  const setValue = (value) => {
    setInput({type: 'updateValue', value});
  };

  const setError = (helper) => {
    setInput({type: 'updateError', error: true});
    setInput({type: 'updateHelper', helper});
  };

  return {
    input,
    changeInput,
    setValue,
    setError
  };
}
