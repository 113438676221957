import {DataGrid} from '@mui/x-data-grid';
import {useTheme} from '@mui/material/styles';
import {EmptyOverlay} from './components/EmptyOverlay';
import {ReactComponent as SortIcon} from '../../assets/icons/icon_sort.svg';
import styles from './DateTable.module.css';

export const DataTable = ({
  columns,
  rows,
  dataGridProps,
  dataGridStyles,
  emptyOverlayProps,
  autoHeight,
  className,
  minHeight,
  maxHeight
}) => {
  const theme = useTheme();

  return (
    <div
      style={{
        minHeight: `${minHeight || 500}px`,
        maxHeight: maxHeight !== undefined ? `${maxHeight}px` : undefined
      }}
      className={styles.parentDataGridContainer}
    >
      <DataGrid
        className={className}
        autoHeight={autoHeight === undefined ? true : autoHeight}
        columns={columns}
        rows={rows ? rows : []}
        sortingOrder={
          ['asc', 'desc']
        }
        rowsPerPageOptions={
          [
            5,
            10,
            25,
            50,
            100
          ]
        }
        hideFooterSelectedRowCount={true}
        GridLinesVisibility="None"
        components={
          {
            NoResultsOverlay: () => <EmptyOverlay {...emptyOverlayProps}/>,
            NoRowsOverlay: () => <EmptyOverlay {...emptyOverlayProps}/>,
            ColumnSortedAscendingIcon: () => <SortIcon className={styles.sortIcon}/>,
            ColumnSortedDescendingIcon: () => <SortIcon className={styles.sortIcon}/>
          }
        }
        initialState={
          {
            sorting: {sortModel: []},
            pagination: {
              page: 1,
              pageSize: 25
            }
          }
        }
        {...dataGridProps}
        sx={
          {
            border: 'none',
            cursor: 'pointer',
            '& .MuiDataGrid-virtualScrollerContent': {minHeight: `${minHeight || 500}px !important`, overflowY: 'auto'},
            '& .MuiDataGrid-columnHeader': {':focus-within': {outline: 'none'}},
            '& .MuiDataGrid-columnHeaderTitle': {marginRight: '0.3rem'},
            '& .MuiDataGrid-columnHeaderTitleContainer': {padding: '0px'},
            '& .MuiDataGrid-columnSeparator': {display: 'none'},
            '& div .MuiDataGrid-cell': {
              border: 'none',
              color: theme.palette.gray.gray900,
              fontSize: '1rem',
              ':focus-within': {outline: 'none'}
            },
            ...dataGridStyles
          }
        }
      />
    </div>
  );
};
