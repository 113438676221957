import {useEffect, useState} from 'react';
import cn from 'classnames';
import {useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import styled from '@emotion/styled';
import styles from './CustomTabs.module.css';

const StyledDiv = styled.div(
  {},
  ({theme, active}) => ({
    background: active
      ? theme.palette.red.main
      : 'transparent',
    color: active
      ? theme.palette.white.main
      : theme.palette.gray.gray250,
    border: active
      ? `1px solid ${theme.palette.red.main}`
      : `1px solid ${theme.palette.gray.gray250}`
  })
);

export const CustomTabs = ({tabs, tabClassName, className, activeTab, onChange}) => {
  const [currentTab, setCurrentTab] = useState(activeTab || tabs[0]);
  const theme = useTheme();

  useEffect(() => {
    setCurrentTab(activeTab);
  }, [activeTab]);

  const onSelect = (tab) => () => {
    setCurrentTab(tab);
    onChange(tab);
  };

  return (
    <Box className={cn(styles.tabs, className)}>
      {
        tabs.map((tab, index) => (
          <StyledDiv
            theme={theme}
            active={tab === currentTab}
            className={cn(styles.tab, tabClassName)}
            key={index}
            onClick={onSelect(tab)}
          >
            {tab}
          </StyledDiv>
        ))
      }
    </Box>
  );
};
