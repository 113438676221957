import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {useTheme} from '@mui/material/styles';

import styles from '../ViewNetwork.module.css';

export const ViewNetworkLink = ({onClick}) => {
  const theme = useTheme();

  return (
    <span>
      <IconButton
        color="primary" onClick={
          (e) => {
            e.stopPropagation();
            onClick();
          }
        } disableRipple
      >
        <VisibilityIcon
          style={{fill: theme.palette.gray.gray100}}
          className={styles.networkLink}
        />
      </IconButton>
    </span>
  );
};
