import companyImage from '../../../../assets/icons/icon_company_black.svg';
import companyImageWhite from '../../../../assets/icons/icon_company_white.svg';
import personImage from '../../../../assets/icons/icon_person_black.svg';
import personImageWhite from '../../../../assets/icons/icon_person_white.svg';
import vesselImage from '../../../../assets/icons/icon_vessel_black.svg';
import vesselImageWhite from '../../../../assets/icons/icon_vessel_white.svg';

export const NodeIcons = {
  person: {
    blueImage: personImage,
    whiteImage: personImageWhite,
    bgColor: '#225B7B'
  },
  vessel: {
    blueImage: vesselImage,
    whiteImage: vesselImageWhite,
    bgColor: '#122945'
  },
  company: {
    blueImage: companyImage,
    whiteImage: companyImageWhite,
    bgColor: '#F75151'
  }
};

const backgroundImageForNode = (label) => ({
  selector: 'node[label=\'' + label + '\']',
  style: {
    backgroundImage: 'url(' + NodeIcons[label].whiteImage + ')',
    backgroundColor: NodeIcons[label].bgColor,
    textBackgroundColor: NodeIcons[label].bgColor,
    textBackgroundOpacity: '0.6',
    color: '#fff',
    padding: '10px',
    'background-width-relative-to': 'inner',
    'background-height-relative-to': 'inner'
  }
});

export default (triton_id, maxConnections) => [
  {
    selector: 'node[id!="' + triton_id + '"]',
    style: {
      width: 100,
      height: 100,
      borderColor: 'rgba(0,0,0,0.22)',
      borderWidth: 3,
      borderOpacity: '0.03',
      textBackgroundOpacity: '0.7',
      textBorderWidth: '15px',
      textBackgroundPadding: ' 5px '
    }
  },
  {
    selector: 'edge',
    style: {
      curveStyle: maxConnections > 3 ? 'bezier' : 'taxi',
      lineColor: '#122945',
      targetArrowColor: '#122945',
      targetArrowShape: 'triangle',
      color: 'black',
      content: '\u24D8',
      fontSize: '20px',
      textBackgroundColor: '#fff',
      textBackgroundOpacity: '1',
      textBackgroundShape: 'round-rectangle'
    }
  },
  backgroundImageForNode('company'),
  backgroundImageForNode('vessel'),
  backgroundImageForNode('person'),
  {
    selector: 'node[id="' + triton_id + '"]',
    style: {
      width: 100,
      height: 100,
      borderColor: 'rgba(0,0,0,0.22)',
      borderWidth: 3,
      borderOpacity: '0.03',
      textBackgroundOpacity: '0.7',
      textBorderWidth: '15px',
      textBackgroundPadding: ' 5px '
    }
  }
];
