import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {CustomAutocomplete} from 'components/CustomAutocomplete/CustomAutocomplete';
import {useSearchStore} from 'stores/hooks/useSearchStore';
import {getValues as getFilterValues, prepareValue} from '../SearchFiltersConfig';
import styles from './FilterComponent.module.css';

const getValues = (decode) => (filters, filterType) => {
  const values = decode(getFilterValues(filters, filterType));

  return Object.entries(values)
    .filter((item) => item[1])
    .map(([key]) => key);
};

export const FilterComponent = ({
  options,
  placeholder,
  type,
  encode,
  decode,
  emptyText
}) => {
  const {searchFilters, updateFilter} = useSearchStore();
  const [value, setValue] = useState(getValues(decode)(searchFilters, type));

  useEffect(() => {
    setValue(getValues(decode)(searchFilters, type));
  }, [type, searchFilters]);

  const onChange = (newValues) => {
    const filterValues = decode(getFilterValues(searchFilters, type));
    Object.keys(filterValues).forEach((key) => {
      filterValues[key] = false;
    });

    newValues.forEach((key) => {
      filterValues[key] = true;
    });
    const filterType = type.split('.')[0];
    if (!filterType) {
      return;
    }

    updateFilter(
      filterType,
      prepareValue(searchFilters, type, encode(filterValues))
    );
    setValue(newValues);
  };

  return (
    <Box className={styles.box}>
      <CustomAutocomplete
        placeholder={placeholder}
        options={options}
        value={value}
        onChange={onChange}
        emptyText={emptyText}
        itemLabelClassName={styles.filterOption}
      />
    </Box>
  );
};
