import {useState} from 'react';
import cn from 'classnames';
import {useTheme} from '@mui/material/styles';
import {Box, Tooltip, Typography} from '@mui/material';
import {CustomFileDragAndDrop} from 'components/CustomFileDragAndDrop/CustomFileDragAndDrop';
import {ReactComponent as DragAndDropIcon} from 'assets/icons/icon_drag_and_drop.svg';
import styles from './CustomDragAndDrop.module.css';

export const CustomDragAndDrop = ({onFiles}) => {
  const [dragOver, setDragOver] = useState(false);
  const theme = useTheme();

  const onDragOver = () => {
    setDragOver(true);
  };

  const onDragEnd = () => {
    setDragOver(false);
  };


  return (
    <CustomFileDragAndDrop
      onDragOver={onDragOver}
      onDragEnd={onDragEnd}
      onDragLeave={onDragEnd}
      className={cn(styles.dragAndDropContainer, dragOver && styles.dragOver)}
      title={
        <Tooltip
          title={
            <Typography
              className={styles.tooltipText}
              textAlign="center"
            >
              Before uploading, make sure that the first line of your csv file contains column names.
            </Typography>
          }
          placement="top"
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            className={styles.dragAndDropTitleContainer}
          >
            <DragAndDropIcon className={styles.dragAndDropIcon}/>
            <Typography
              color="secondary"
              className={styles.dragAndDropTitle}
            >
              Select CSV file to upload
            </Typography>
            <Typography
              style={
                {color: theme.palette.gray.gray100}
              }
              className={styles.dragAndDropSubTitle}
            >
              or drag and drop it here
            </Typography>
          </Box>
        </Tooltip>
      }
      onFiles={(...params) => {
        setDragOver(false);
        onFiles(...params);
      }}
      formats={['csv']}
    />
  );
};
