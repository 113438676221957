import {useMemo} from 'react';
import * as yup from 'yup';
import cn from 'classnames';
import {useNavigate} from 'react-router';
import {enqueueSnackbar} from 'notistack';
import {Box, Button, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {CustomInput} from 'components/CustomInput/CustomInput';
import {Loader} from 'components/Loader/Loader';
import {useApi} from 'hooks/useApi';
import {usersService} from 'api/services/users';
import styles from './AccountInfoContent.module.css';

const schema = yup
  .object({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    email: yup.string().email().required()
  })
  .required();


export const AccountInfoContent = ({user, onUpdate}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const updateProfileService = useApi({
    service: usersService.updateUser,
    immediate: false
  });
  const {
    control,
    handleSubmit,
    formState: {errors},
    watch,
    setError
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {...user}
  });

  const values = watch([
    'first_name',
    'last_name',
    'email'
  ]);

  const onSubmit = async (userData) => {
    const result = await updateProfileService.execute(userData);

    if (result instanceof Error) {
      const responseData = result?.response?.data || {};

      Object.entries(responseData).forEach(([field, errors]) => {

        errors.forEach((error, index) => {
          setError(field, {type: `custom-${index}`, message: error});
        });
      });

      const config = {
        preventDuplicate: true,
        key: `update-user-profile-error-${user.id}`,
        autoHideDuration: 2500,
        variant: 'error'
      };

      enqueueSnackbar('An error occurred when you tried to update your profile.', config);
      return;
    }
    onUpdate({...userData});
  };

  const resetPassword = () => {
    navigate('/reset-password', {state: {email: user.email}});
  };

  const userProfileModified = useMemo(() => JSON.stringify(values) !== JSON.stringify([
    user.first_name,
    user.last_name,
    user.email
  ]), [user, values]);

  return (
    <Box
      className={styles.accountInfoContainer}
      display="flex"
      flexDirection="column"
    >
      <Loader absolute={true} loading={updateProfileService.loading}/>
      <Box
        display="flex"
        className={styles.usernameInputs}
        flexWrap="wrap"
      >
        <Box className={styles.usernameInput}>
          <Controller
            control={control}
            rules={
              {required: true}
            }
            render={
              ({field: {...props}}) => (
                <CustomInput
                  className={styles.userField}
                  label="First Name"
                  required={true}
                  placeholder="First Name"
                  disabled={updateProfileService.loading}
                  {...props}
                />
              )
            }
            name="first_name"
          />
          {
            !!errors.first_name && (
              <Typography className={styles.errorMessage}>
                {errors.first_name.message}
              </Typography>
            )
          }
        </Box>
        <Box className={styles.usernameInput}>
          <Controller
            control={control}
            rules={
              {required: true}
            }
            render={
              ({field: {...props}}) => (
                <CustomInput
                  className={styles.userField}
                  label="Last Name"
                  required={true}
                  placeholder="Last Name"
                  disabled={updateProfileService.loading}
                  {...props}
                />
              )
            }
            name="last_name"
          />
          {
            !!errors.last_name && (
              <Typography className={styles.errorMessage}>
                {errors.last_name.message}
              </Typography>
            )
          }
        </Box>
      </Box>
      <Controller
        control={control}
        rules={
          {required: true}
        }
        render={
          ({field: {...props}}) => (
            <CustomInput
              {...props}
              disabled={true}
              className={styles.userField}
              label="Email"
              required={true}
              placeholder="Email"
            />
          )
        }
        name="email"
      />
      {
        !!errors.email && (
          <Typography className={styles.errorMessage}>
            {errors.email.message}
          </Typography>
        )
      }
      <CustomInput
        className={cn(styles.userField, styles.lastField)}
        label="Role"
        placeholder="Role"
        disabled={true}
        value={user.is_admin ? 'Admin' : 'User'}
      />
      <Box
        className={styles.profileFooter}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Button
          variant="outlined"
          type="submit"
          onClick={resetPassword}
        >
          Reset Password
        </Button>
        {
          !!userProfileModified && (
            <Button
              variant="contained"
              type="submit"
              onClick={handleSubmit(onSubmit)}
              sx={
                {background: theme.palette.red.main}
              }
            >
              Edit Profile
            </Button>
          )
        }
      </Box>
    </Box>
  );
};
