import {Box, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import cn from 'classnames';
import {NoMaxWidthTooltip} from 'components/NoMaxWidthTooltip/NoMaxWidthTooltip';
import {ReactComponent as IUUStatusIcon} from 'assets/icons/icon_iuu_status.svg';
import styles from './IUUStatus.module.css';

export const isVessel = (entity_type) => entity_type === 'vessel';

export const IUUStatus = ({
  iuuStatus,
  iconMode,
  empty = true,
  color,
  style,
  entity_type,
  className
}) => {
  const theme = useTheme();
  let value = iuuStatus;
  value = value === 'neverListed' ? null : value;

  if (!isVessel(entity_type) || !value) {
    return empty ? '' : (
      <Typography
        style={{
          backgroundColor: theme.palette.secondary.alpha10,
          ...style
        }}
        color={color}
        className={cn(styles.iuuStatusText, className)}
      >
        N/A
      </Typography>
    );
  }

  return (
    iconMode ? (
      <NoMaxWidthTooltip
        arrow
        backgroundColor={theme.palette.secondary.main}
        title={
          <Box
            className={styles.tooltipTextContainer}
            display="flex"
            alignItems="center"
            flexWrap="none"
          >
            <Typography className={styles.tooltipText}>
              This vessel is IUU
            </Typography>
            <Typography className={styles.tooltipBoldText}>
              {value === 'listed' ? 'listed.' : 'delisted.'}
            </Typography>
          </Box>
        }
        placement="top-end"
      >
        <IUUStatusIcon
          className={cn(styles.iuuStatus, className)}
          style={{
            fill: value === 'listed'
              ? theme.palette.secondary.secondary200
              : theme.palette.gray.gray100
          }}
        />
      </NoMaxWidthTooltip>
    )
      : (
        <Typography
          color={color}
          className={cn(styles.iuuStatusText, className)}
          style={
            {
              backgroundColor: value === 'listed'
                ? theme.palette.red.alpha10
                : theme.palette.secondary.alpha10,
              ...style
            }
          }
        >
          {iuuStatus}
        </Typography>
      )
  );
};
