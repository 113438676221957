import {useState, useEffect} from 'react';
import Box from '@mui/material/Box/Box';
import Typography from '@mui/material/Typography/Typography';
import Stack from '@mui/material/Stack/Stack';
import {useTheme} from '@mui/material/styles';

import CompanyNetwork from 'assets/previews/preview_company_network.png';
import PersonNetwork from 'assets/previews/preview_person_network.png';
import VesselNetwork from 'assets/previews/preview_vessel_network.png';
import styles from '../ViewNetwork.module.css';

export const ViewNetworkButton = ({entityType, handleClick}) => {
  const [imageSource, setImageSource] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    if (entityType === 'company') {
      setImageSource(CompanyNetwork);
    } else if (entityType === 'person') {
      setImageSource(PersonNetwork);
    } else if (entityType === 'vessel') {
      setImageSource(VesselNetwork);
    } else {
      setImageSource(null);
    }
  }, [entityType]);

  const onClick = (e) => {
    e.stopPropagation();
    if (handleClick) {
      handleClick();
    }
  };

  return (
    <Stack spacing={1}>
      {
        !!imageSource && (
          <img
            src={imageSource}
            height="auto"
            width="100%"
            alt={`${entityType}-network`}
            className={styles.networkButtonContainer}
            onClick={onClick}
          />
        )
      }
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={styles.networkButton}
        sx={
          {backgroundColor: theme.palette.steel.alpha80}
        }
        onClick={onClick}
      >
        <Typography color="white.main" subvariant="link">
          Click to see full graph
        </Typography>
      </Box>
    </Stack>
  );
};
