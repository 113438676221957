import {useTheme} from '@mui/material/styles';
import styles from './Line.module.css';

export const Line = () => {
  const theme = useTheme();
  return (
    <span
      style={
        {background: theme.palette.gray.gray100}
      }
      className={styles.line}
    />
  );
};
