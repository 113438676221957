import {useContext} from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {SearchResultsTable} from 'components/SearchResultsTable/SearchResultsTable';
import {HorizontalDivider} from 'components/Dividers/HorizontalDivider';
import {useUpdateService} from 'stores/hooks/useUpdateService';
import {useSearchStore} from 'stores/hooks/useSearchStore';
import {useApi} from 'hooks/useApi';
import {entitiesService} from 'api/services/entities';
import {SEARCH_PAGE_BROADCASTER, SearchContext} from '../../SearchPage';
import styles from './SearchResults.module.css';

export const SearchResults = ({className, onPagination, onClickCell, viewNetworksHandleClick}) => {
  const {updateRow} = useContext(SearchContext);
  const {paginationFilters, loading, data} = useSearchStore();
  const {updateService} = useUpdateService();

  const searchBroadcaster = updateService.broadcasters[SEARCH_PAGE_BROADCASTER];
  const favorite = useApi({
    service: entitiesService.favorite,
    immediate: false
  });
  const unfavorite = useApi({
    service: entitiesService.unfavorite,
    immediate: false
  });

  const handleUpdateFavorite = async (is_favorite, triton_id) => {
    const payload = {triton_id};
    const result = is_favorite ? await favorite.execute(payload) : await unfavorite.execute(payload);
    if (result instanceof Error) {
      return;
    }

    updateRow(triton_id, {is_favorite});

    if (searchBroadcaster) {
      searchBroadcaster.sendData({
        type: 'isFavorite',
        isFavorite: is_favorite,
        tritonId: triton_id
      }, true);
      /*
        sendData(data, true) means except index subscriber
      */
    }
  };

  const onPage = (page) => {
    onPagination({
      ...paginationFilters,
      page
    });
  };

  const onPageSize = (pageSize) => {
    onPagination({
      ...paginationFilters,
      pageSize
    });
  };

  const onSort = (ordering) => {
    onPagination({
      ...paginationFilters,
      ordering
    });
  };

  return (
    <Grid container spacing={5} className={className}>
      <Grid item xs={12} className={styles.searchResultsTitleContainer}>
        <Typography variant="h6" color="secondary" className={styles.searchResultsTitle}>
          Search Results
        </Typography>
      </Grid>
      <Grid item xs={12} className={styles.searchResultsTableGrid}>
        <Box className={styles.searchResultsTableWrapper}>
          <SearchResultsTable
            rows={data?.results || []}
            loading={loading}
            onClickCell={onClickCell}
            viewNetworksHandleClick={viewNetworksHandleClick}
            onFavorite={handleUpdateFavorite}
            pagination={
              {
                count: data?.count || 0,
                page: paginationFilters.page,
                pageSize: paginationFilters.pageSize
              }
            }
            onPage={onPage}
            onPageSize={onPageSize}
            onSort={onSort}
          />
        </Box>
        <HorizontalDivider/>
      </Grid>
    </Grid>
  );
};
