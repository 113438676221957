/* eslint-disable camelcase */
import jwt_decode from 'jwt-decode';
import {TOKENS_KEY, ACCESS_TOKEN_KEY} from 'constants/localStorage';
import {inFuture} from 'utils/time';
import {httpErrorLighthouse} from '../stores/contexts/HttpErrorStore';

export function getTokens() {
  let tokens;
  try {
    tokens = JSON.parse(localStorage.getItem(TOKENS_KEY));
  } catch {
    return null;
  }

  if (tokens && tokens[ACCESS_TOKEN_KEY]) {
    return tokens;
  } else {
    return null;
  }
}

export function getAccessToken() {
  const tokens = getTokens();

  if (tokens) {
    return tokens[ACCESS_TOKEN_KEY];
  } else {
    return null;
  }
}

export function removeTokens() {
  localStorage.removeItem(TOKENS_KEY);
}

export function setTokens(tokens) {
  if (tokens && tokens[ACCESS_TOKEN_KEY]) {
    localStorage.setItem(TOKENS_KEY, JSON.stringify(tokens));
  } else {
    removeTokens();
  }
}

export function logout(showMessage) {
  if (showMessage) {
    httpErrorLighthouse.updateState(401, 'Your session is expired.');
  }
  removeTokens();
}

export function loggedIn() {
  const tokens = getTokens();
  let isLoggedIn = false;
  try {
    const refreshToken = jwt_decode(tokens[ACCESS_TOKEN_KEY]);
    isLoggedIn = inFuture(refreshToken.exp);
  } catch {
    isLoggedIn = false;
  }

  return isLoggedIn;
}
