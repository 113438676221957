import {createContext, useEffect, useMemo, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {Outlet, useLocation} from 'react-router';
import {SEARCH_FILTERS} from 'constants/entities';
import {useApi} from 'hooks/useApi';
import {entitiesService} from 'api/services/entities';
import {
  customArrayParam,
  customMapParam,
  customNumberParam,
  customOrderingParam,
  customPageSizeParam,
  customStringParam,
  makeDecodeSearchParamsFunction,
  makeEncodeDataFunction
} from 'utils/queryParams';
import {deepClone, removeFalseKeys, setValue} from 'utils/helpers';
import {useProfileStore} from '../hooks/useProfileStore';

const initialState = {
  searchFilters: SEARCH_FILTERS,
  paginationFilters: {
    page: 0,
    pageSize: 10,
    ordering: {
      sort: '',
      field: ''
    }
  }
};

export const SearchStoreContext = createContext(deepClone(initialState));

const searchQueryConfig = {
  name: customStringParam('searchFilters.textFilters.name', ''),
  sources: customArrayParam('searchFilters.textFilters.sources', []),

  page: customNumberParam('paginationFilters.page', 0),
  pageSize: customPageSizeParam('paginationFilters.pageSize', 10),
  ordering: customOrderingParam('paginationFilters.ordering', {
    sort: 'desc',
    field: 'label'
  }),
  countries: customArrayParam('searchFilters.countries', []),
  typeFilters: customMapParam('searchFilters.typeFilters', SEARCH_FILTERS.typeFilters),
  statusFilters: customMapParam('searchFilters.statusFilters', SEARCH_FILTERS.statusFilters),
  fleetFilters: customMapParam('searchFilters.fleetFilters', SEARCH_FILTERS.fleetFilters),
  sanctionFilters: customMapParam('searchFilters.sanctionFilters', SEARCH_FILTERS.sanctionFilters)
};

const encodeFilters = makeEncodeDataFunction(searchQueryConfig);
const decodeSearchParams = makeDecodeSearchParamsFunction(searchQueryConfig);

const makeSearchPayload = (filters) => {
  const sources = filters.typeFilters.vessel ? filters.textFilters.sources : [];

  return {
    searchTexts: {
      name: filters.textFilters.name,
      sources: sources
    },
    entityTypes: removeFalseKeys(filters.typeFilters, true),
    iuuStatuses: filters.typeFilters.vessel ? removeFalseKeys(filters.statusFilters, true) : undefined,
    fleetTags: filters.typeFilters.vessel ? removeFalseKeys(filters.fleetFilters, true) : undefined,
    sanctionStatuses: filters.typeFilters.vessel ? removeFalseKeys(filters.sanctionFilters, true) : undefined,
    countries: filters.countries
  };
};

const SearchStoreProvider = ({children}) => {
  const location = useLocation();
  const [profile] = useProfileStore();
  const [searchParams, setSearchParams] = useSearchParams();
  // eslint-disable-next-line react/hook-use-state
  const [{paginationFilters, searchFilters}, setSearchFilters] = useState(
    decodeSearchParams(searchParams, deepClone(initialState))
  );

  const paginationParams = useMemo(() => ({
    page: paginationFilters.page + 1,
    'page_size': paginationFilters.pageSize,
    ordering: paginationFilters.ordering
  }), [paginationFilters]);

  const search = useApi({
    service: entitiesService.search,
    payload: makeSearchPayload(searchFilters),
    queryParams: paginationParams,
    immediate: false
  });
  const [data, setData] = useState(search?.data);

  useEffect(() => {
    setData(search?.data);
  }, [search?.data]);

  useEffect(() => {
    if (location.pathname === '/search') {
      if (!search.loading && profile.user) {
        search.execute(makeSearchPayload(searchFilters), paginationParams)
          .then((result) => {
            if (result instanceof Error) {
              // eslint-disable-next-line no-use-before-define
              setPaginationFilter({
                ...paginationFilters,
                page: 0
              });
            }
          });
      }
    }
  }, [location.pathname, profile.user]);

  const setFilters = (filters) => {
    setSearchFilters(filters);

    setSearchParams(encodeFilters(filters));
  };

  const setSearchFilter = (searchFilters) => {
    setFilters({
      paginationFilters: {
        ...paginationFilters,
        page: 0
      },
      searchFilters
    });
  };

  const setPaginationFilter = (paginationFilters) => {
    setFilters({paginationFilters, searchFilters});
    search.execute(makeSearchPayload(searchFilters), {
      page: paginationFilters.page + 1,
      'page_size': paginationFilters.pageSize,
      ordering: paginationFilters.ordering
    });
  };

  const updateFilter = (address, value) => {
    const newSearchFilter = {...searchFilters};

    setValue(newSearchFilter, address, value);
    setSearchFilter(newSearchFilter);
  };

  const execute = (inputSearchFilters = undefined, returnSearchParams = false) => {
    const newSearchFilters = inputSearchFilters || searchFilters;

    const filters = {
      searchFilters: {
        ...searchFilters,
        ...newSearchFilters
      },
      paginationFilters
    };

    if (inputSearchFilters) {
      setSearchFilters(filters);
    }

    search.execute(makeSearchPayload(filters.searchFilters), paginationParams);

    if (returnSearchParams) {
      return encodeFilters(filters);
    }
  };

  const reset = () => {
    setFilters(deepClone(initialState));
  };

  const updateRow = (tritonId, data) => {
    setData((prev) => {
      const cloned = {...prev};

      if (!cloned) {
        return cloned;
      }

      const results = cloned.results.slice();
      const index = results.findIndex((row) => row.triton_id === tritonId);
      results[index] = {...results[index], ...data};

      cloned.results = results;

      return cloned;
    });
  };

  return (
    <SearchStoreContext.Provider value={
      {
        paginationFilters,
        searchFilters,
        updateFilter,
        setSearchFilter,
        setPaginationFilter,
        execute,
        reset,
        updateRow,
        loading: search.loading,
        data
      }
    }
    >
      {children}
    </SearchStoreContext.Provider>
  );
};

export const SearchStoreLayout = () => {
  return <SearchStoreProvider><Outlet /></SearchStoreProvider>;
};
