import {createContext} from 'react';
import {useApi} from 'hooks/useApi';
import {usersService} from 'api/services/users';
import {organizationServices} from 'api/services/organizations';


const initialState = {
  users: {},
  organizations: {}
};

export const AdminStoreContext = createContext(initialState);

export const AdminStoreWrapper = ({children}) => {
  const users = useApi({
    service: usersService.adminUsers,
    immediate: true
  });
  const organizations = useApi({
    service: organizationServices.organizations,
    immediate: true
  });

  return (
    <AdminStoreContext.Provider value={{
      users,
      organizations
    }}
    >
      {children}
    </AdminStoreContext.Provider>
  );
};
