import {useMemo} from 'react';
import {Box, IconButton} from '@mui/material';
import {ColumnHeader} from 'components/DataTable/components/ColumnHeader/ColumnHeader';
import {DataTable} from 'components/DataTable/DataTable';
import {TableCell} from '../../components/Table/TableCell/TableCell';
import {SectionHeader} from '../../components/SectionHeader/SectionHeader';
import {ReactComponent as DeleteIcon} from 'assets/icons/icon_delete.svg';

export const OrganizationUsers = ({users, onRemove}) => {
  const columns = useMemo(() => [
    {
      field: 'name',
      renderHeader: () => <ColumnHeader label="Name"/>,
      disableColumnMenu: true,
      minWidth: 200,
      renderCell: ({row}) => {
        const nameParts = [row?.first_name, row?.last_name];

        return (
          <TableCell label={nameParts.filter(Boolean).join(' ')} color="secondary"/>
        );
      }
    },
    {
      field: 'email',
      renderHeader: () => <ColumnHeader label="Email"/>,
      disableColumnMenu: true,
      minWidth: 150,
      flex: 1,
      renderCell: ({value}) => <TableCell label={value || ''} color="secondary"/>
    },
    {
      field: 'actions',
      renderHeader: () => <ColumnHeader label="Actions" justifyContent="center"/>,
      disableColumnMenu: true,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: ({row}) => (
        <IconButton
          onClick={
            () => {
              onRemove(row.id);
            }
          }
        >
          <DeleteIcon color="secondary"/>
        </IconButton>
      )
    }
  ], [users]);

  return (
    <Box width="100%">
      <SectionHeader line={false} unboxed={true}/>
      <Box maxHeight={340} minHeight={240} width="100%">
        <DataTable
          minHeight={240}
          maxHeight={340}
          columns={columns}
          rows={users}
          autoHeight={true}
          dataGridProps={
            {
              getRowId: (row) => row.id,
              loading: false,
              sortingMode: 'client',
              pagination: true,
              paginationMode: 'client',
              rowCount: users.length,
              page: 0,
              hideFooterPagination: true,
              pageSize: users.length
            }
          }
          dataGridStyles={
            {
              '& .MuiDataGrid-columnHeaders': {border: 'none'},
              '& .MuiDataGrid-virtualScroller': {
                maxHeight: '300px',
                overflowY: 'auto !important',
                overflowX: 'hidden',
                boxShadow: 'inset 0 -14px 14px -14px rgb(147 147 147 / 50%)'
              },
              '& .MuiDataGrid-virtualScrollerContent': {minHeight: '240px!important'}
            }
          }
          emptyOverlayProps={{text: 'There are currently no users.'}}
        />
      </Box>
    </Box>
  );
};
