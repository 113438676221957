import React, {useMemo} from 'react';
import styled from '@emotion/styled';
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';

export const StyledTooltip = styled(
  ({backgroundColor, children, className, transformArrow: _unused, ...props}) => (
    <Tooltip {...props} classes={{popper: className}}>{children}</Tooltip>
  )
)(({transformArrow = true, ...props}) => {
  const arrowStyles = useMemo(() => {
    if (transformArrow) {
      return {
        [`& .${tooltipClasses.tooltip} .${tooltipClasses.arrow}`]: {
          color: props.backgroundColor,
          transform: 'translate3d(14px, 0px, 0px) !important'
        }
      };
    } else {
      return {[`& .${tooltipClasses.tooltip} .${tooltipClasses.arrow}`]: {color: props.backgroundColor}};
    }
  }, [transformArrow]);
  return {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: props.backgroundColor,
      borderRadius: 8,
      padding: '8px 12px',
      marginLeft: '-14px'
    },
    ...arrowStyles
  };
});
