import {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';
import {Box, Modal} from '@mui/material';
import {InviteSingleUser} from './InviteSingleUser';
import {InviteUsers} from './InviteUsers';
import {ModalContainer} from '../../../components/ModalContainer/ModalContainer';
import {ModalHeader} from '../../../components/ModalHeader/ModalHeader';
import {SectionHeader} from '../../../components/SectionHeader/SectionHeader';
import styles from '../../../Admin.module.css';

// eslint-disable-next-line react/display-name
export const InviteUser = forwardRef(({organization}, ref) => {
  const setUsers = useRef(null);
  const [hideModal, setHideModal] = useState(false);
  const [openState, setOpenState] = useState(false);
  const [mode, setMode] = useState('single');

  useImperativeHandle(ref, () => ({
    open: async (mode) => {
      // mode single or bulk
      setOpenState(true);
      setMode(mode);

      return new Promise((resolve) => {
        setUsers.current = (users) => {
          // users objs or undefined
          resolve(users);
        };
      });
    }
  }), []);

  const handleClose = () => {
    setOpenState(false);
    setUsers.current(undefined);
  };

  const Component = useMemo(() => mode === 'single' ? InviteSingleUser : InviteUsers, [mode]);

  const modalHeader = useMemo(() => mode === 'single'
    ? `Add User to ${organization?.name || ''}`
    : `Add Users to ${organization?.name || ''}`,
  [mode]
  );
  const modalSectionTitle = useMemo(() => mode === 'single' ? 'User Details' : 'Users Details');

  const onUsers = (users, openState = false) => {
    setUsers.current(users);
    setOpenState(openState);
  };

  const onHideModal = () => {
    setHideModal(true);
  };

  const onShowModal = () => {
    setHideModal(false);
  };

  return (
    <Modal
      style={{opacity: hideModal ? 0 : 1}}
      open={openState}
      onClose={handleClose}
    >
      <ModalContainer
        className={styles.modalContent}
        flexDirection="column"
      >
        <ModalHeader onClose={handleClose} title={modalHeader}/>
        <Box className={styles.details}>
          <Box className={styles.details}>
            <SectionHeader title={modalSectionTitle}/>
            <Component
              onUsers={onUsers}
              organization={organization}
              onHideModal={onHideModal}
              onShowModal={onShowModal}
            />
          </Box>
        </Box>
      </ModalContainer>
    </Modal>
  );
});
