import React from 'react';
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';
import styled from '@emotion/styled';

export const NoMaxWidthTooltip = styled(
  ({className, backgroundColor, children, ...props}) => (
    <Tooltip {...props} classes={{popper: className}} >{children}</Tooltip>
  )
)((props) => {
  return {
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 'none',
      backgroundColor: props.backgroundColor
    },
    [`& .${tooltipClasses.tooltip} .${tooltipClasses.arrow}`]: {color: props.backgroundColor}
  };
});
