import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import {useTheme} from '@mui/material/styles';
import cn from 'classnames';
import logoImage from 'assets/logos/logo_c4ads_black.png';
import {
  ABOUT_TRITON_URL,
  PRIVACY_URL,
  LICENSE_URL,
  CHANGELOG_URL
} from 'constants/urls';
import styles from './Footer.module.css';

export const Footer = () => {
  const theme = useTheme();

  return (
    <Box
      className={styles.wrapper}
      sx={
        {backgroundColor: theme.palette.white.main}
      }
    >
      <Container maxWidth="xl" className={styles.innerContainer}>
        <Grid
          container
          justifyContent="space-between"
          justifyItems="center"
          alignContent="center"
          alignItems="center"
          spacing={5}
        >
          <Grid item xs={12} md={2} lg={4}>
            <img
              src={logoImage}
              alt="logo-c4ads-black"
              height="40px"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6} justifyItems="flex-end">
            <Stack className={styles.linksStack}>
              <Link
                href={PRIVACY_URL}
                variant="body1"
                target="_blank"
                rel="noopener"
                color="black.main"
                underline="hover"
                className={styles.link}
              >
                Privacy Policy
              </Link>
              <Link
                href={LICENSE_URL}
                variant="body1"
                target="_blank"
                rel="noopener"
                color="black.main"
                underline="hover"
                className={styles.link}
              >
                EULA
              </Link>
              <Link
                href={ABOUT_TRITON_URL}
                variant="body1"
                target="_blank"
                rel="noopener"
                color="black.main"
                underline="hover"
                className={styles.link}
              >
                About
              </Link>
              <Link
                href={CHANGELOG_URL}
                variant="body1"
                target="_blank"
                rel="noopener"
                color="black.main"
                underline="hover"
                className={styles.link}
              >
                Changelog
              </Link>
            </Stack>
            <Typography color="black.main" className={cn(styles.additionalInfo, styles.text)}>
              {`@ ${(new Date()).getFullYear()} Center for Advanced Defense Studies, Inc.`}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
