import {removeArrayDuplicates} from 'utils/helpers';

export const getChainItems = (selectedEdges, allNodes) => {
  return removeArrayDuplicates(
    selectedEdges.map((edge) => {
      const parentNode = allNodes.find((node) => node.triton_id === edge.source);
      const childNode = allNodes.find((node) => node.triton_id === edge.target);

      return [
        {type: 'node', node: parentNode},
        {type: 'edge', edge},
        {type: 'node', node: childNode}
      ];
    }).flat(1)
  );
};

export const getChainToParent = (parentId, currentId, edges, chain = [], viewedNodes = []) => {
  if (viewedNodes.includes(currentId)) {
    return [];
  }
  viewedNodes.push(currentId);

  const currentEdges = edges.filter((edge) => edge.target === currentId);
  const finalEdge = currentEdges.find((edge) => edge.source === parentId);
  if (finalEdge) {
    return chain.concat([finalEdge]).reverse();
  }

  return currentEdges
    .map((edge) => getChainToParent(
      parentId,
      edge.source,
      edges,
      chain.concat([edge]),
      viewedNodes.slice()
    ))
    .filter((item) => item?.length)
    .map((edges) => edges.flat(Infinity));
};

export const getChainToChild = (childId, currentId, edges, chain = [], viewedNodes = []) => {
  if (viewedNodes.includes(currentId)) {
    return [];
  }
  viewedNodes.push(currentId);

  const currentEdges = edges.filter((edge) => edge.source === currentId);

  const finalEdge = currentEdges.find((edge) => edge.target === childId);
  if (finalEdge) {
    return chain.concat([finalEdge]);
  }

  return currentEdges
    .map((edge) => getChainToChild(
      childId,
      edge.target,
      edges,
      chain.concat([edge]),
      viewedNodes.slice()
    ))
    .filter((item) => item?.length)
    .map((edges) => edges.flat(Infinity));
};

export const preparingNodes = (nodes) => {
  return nodes
    .map((node) => ({
      triton_id: node.triton_id,
      scheme: node.entity.entity_type,
      name: node.entity.label,
      nameMentioned: node.entity.label,
      iuuStatus: node.entity.iuu_status,
      startDate: new Date(node.entity.modified)
    }));
};

export const getNodeIndex = (nodes, triton_id) => {
  return nodes.findIndex((node) => node.triton_id === triton_id);
};

export const getNode = (nodes, tritonId) => {
  const currentNodes = nodes || [];
  const index = getNodeIndex(currentNodes, tritonId);
  return index === -1 ? undefined : currentNodes[index];
};
