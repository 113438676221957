import React, {useEffect, useState} from 'react';
import {
  Box,
  OutlinedInput,
  MenuItem,
  Select,
  Chip,
  Typography,
  InputLabel,
  FormControl
} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import cn from 'classnames';
import styles from './CustomChipSelect.module.css';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const validateOptions = (options, maxOptions) => maxOptions ? options.slice(0, maxOptions) : options;

export const CustomChipSelect = ({
  maxOptions,
  placeholder,
  label,
  options,
  onChange,
  prefix,
  renderLabel,
  disabled,
  open: currentOpen,
  value: currentValue,
  onOpenState,
  closeAfterOptionClick,
  chipClassName
}) => {
  const [open, setOpen] = useState(currentOpen || false);
  const [value, setValue] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    setOpen(currentOpen || false);
  }, [currentOpen]);

  useEffect(() => {
    const arr = currentValue || [];

    setValue(validateOptions(arr, maxOptions));
  }, [currentValue]);

  const handleChange = (event) => {
    const {target: {value: targetValue}} = event;

    let result = targetValue;
    const lastIndex = result.length - 1;
    if (lastIndex > 0) {
      const option = result.splice(lastIndex, 1);
      result = option.concat(result);
    }
    const newValue = validateOptions(result, maxOptions);

    setValue(newValue);
    onChange(newValue);
  };

  const onOpen = () => {
    setOpen(true);
    onOpenState && onOpenState(true);
  };

  const onClose = () => {
    setOpen(false);
    onOpenState && onOpenState(false);
  };

  const menuItemOnClick = () => {
    if (closeAfterOptionClick) {
      onClose();
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      className={styles.box}
    >
      {
        !!label && (
          <Typography
            className={styles.label}
            style={
              {color: theme.palette.secondary.secondary200}
            }
          >
            {label}
          </Typography>
        )
      }
      <FormControl fullWidth>
        {
          !!placeholder && (
            <InputLabel className={cn(styles.placeholder, (open || value.length) && styles.hidePlaceholder)}>
              <Typography className={styles.placeholderText}>
                {placeholder}
              </Typography>
            </InputLabel>
          )
        }
        <Select
          multiple
          onClose={onClose}
          onOpen={onOpen}
          open={open}
          className={styles.select}
          value={value}
          placeholder={placeholder}
          onChange={handleChange}
          input={<OutlinedInput />}
          disabled={disabled}
          renderValue={(selected) => (
            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
              {selected.map((value) => (
                <Chip className={chipClassName} key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {prefix}
          {options.map((option) => (
            <MenuItem
              onClick={menuItemOnClick}
              key={option}
              value={option}
              style={{padding: renderLabel ? 0 : undefined}}
            >
              {renderLabel ? renderLabel(option) : option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
