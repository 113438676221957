import {useEffect, useMemo, useState} from 'react';
import {useQueryParams, StringParam} from 'use-query-params';
import {entitiesService} from 'api/services/entities';
import {useApi} from 'hooks/useApi';
import {NetworksGraph} from './components/NetworkGraph/NetworksGraph';
import {NetworkGraphNotFound} from './components/NetworkGraphNotFound/NetworkGraphNotFound';
import {DetailsPanel} from 'components/DetailsPanel/DetailsPanel';
import {HttpErrorHandler} from 'components/HttpErrorHandler/HttpErrorHandler';
import {Loader} from 'components/Loader/Loader';
import {AccessDenied} from 'components/AccessDenied/AccessDenied';
import styles from './NetworksPage.module.css';

export const NetworksPage = (props) => {
  const {disableQuery, broadcaster} = props;
  const [query, setQuery] = useQueryParams({triton_id: StringParam});
  const {triton_id} = query;
  const networkRoot = triton_id;
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(false);
  const [detailsPanelOpen, setDetailsPanelOpen] = useState(false);

  const details = useApi({
    service: entitiesService.details,
    immediate: false
  });
  const network = useApi({
    service: entitiesService.network,
    immediate: false
  });
  const networkWrapped = useApi({
    service: entitiesService.networkWrapped,
    immediate: false
  });
  const networkWrapped2 = useApi({
    service: entitiesService.networkWrapped,
    immediate: false
  });

  useEffect(() => {
    if (!disableQuery) {
      setQuery({triton_id: networkRoot}, 'replace');
    }
  }, [networkRoot]);

  useEffect(() => {
    if (networkRoot) {
      setReady(false);
      const promises = Promise.all([
        networkWrapped.execute({
          root: {triton_id: networkRoot},
          method: 'self_and_ancestors'
        }),
        networkWrapped2.execute({
          root: {triton_id: networkRoot},
          method: 'descendants'
        })
      ]);

      promises.then((results) => {
        const dataNodes = results.map((data) => data?.nodes || []).flat(1);
        const dataEdges = results.map((data) => data?.edges || []).flat(1);

        setData({
          nodes: dataNodes,
          edges: dataEdges
        });
        setReady(true);
      });
    }
  }, [networkRoot]);

  useEffect(() => {
    if (networkRoot) {
      network.execute({root: {triton_id: networkRoot}});
    }
  }, [networkRoot]);

  const onClosePanel = () => {
    setDetailsPanelOpen(false);
  };

  const onClickNode = async (tritonId) => {
    setLoading(true);
    await details.execute(tritonId);
    setLoading(false);
    setDetailsPanelOpen(true);
  };

  const networkComponent = useMemo(() => {
    if (network.status === 403) {
      return (
        <AccessDenied
          absolute={true}
          title="You do not have permission to access the network graph."
        />
      );
    }

    if (data?.nodes && ready) {
      return (
        <NetworksGraph
          detailsData={details.data}
          network={data}
          triton_id={networkRoot}
          onClickNode={onClickNode}
        />
      );
    } else if (!data?.nodes && ready && !network.loading) {
      return <NetworkGraphNotFound />;
    }

    return null;
  }, [
    details.data,
    data,
    ready,
    network
  ]);

  return (
    <>
      <HttpErrorHandler/>
      <Loader loading={loading}/>
      <div className={styles.container}>
        <Loader loading={!ready} absolute={true}/>
        {networkComponent}
      </div>
      <div>
        <DetailsPanel
          broadcaster={broadcaster}
          data={details.data}
          open={detailsPanelOpen}
          onClose={onClosePanel}
          showNetworkButton={true}
          onCloseAfterNetworksOnClick={true}
        />
      </div>
    </>
  );
};
