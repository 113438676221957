import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import {CssBaseline} from '@mui/material';
import 'index.css';
import {App} from 'App';

Sentry.init({
  dsn: 'https://de104a4543b6425dae3fb333ce431998@o295592' + '.ingest.sentry.io/4504243041402880',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0
});

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
