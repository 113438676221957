import {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';
import {Box, Modal} from '@mui/material';
import {BulkCreation} from './BulkCreation';
import {SingleCreation} from './SingleCreation';
import {ModalHeader} from '../../components/ModalHeader/ModalHeader';
import {ModalContainer} from '../../components/ModalContainer/ModalContainer';
import {SectionHeader} from '../../components/SectionHeader/SectionHeader';
import {CreationType} from '../../components/CreationType/CreationType';
import styles from '../../Admin.module.css';

const userDetailsComponents = {
  'single': SingleCreation,
  'bulk': BulkCreation
};

// eslint-disable-next-line react/display-name
export const AddUser = forwardRef((props, ref) => {
  const setUsers = useRef(null);
  const [hideModal, setHideModal] = useState(false);
  const [openState, setOpenState] = useState(false);
  const [creationType, setCreationType] = useState('single');

  useImperativeHandle(ref, () => ({
    open: async () => {
      setOpenState(true);

      return new Promise((resolve) => {
        setUsers.current = (users) => {
          // user obj or undefined
          resolve(users);
        };
      });
    }
  }), []);

  const handleClose = () => {
    setOpenState(false);
    if (setUsers.current) {
      setUsers.current(undefined);
    }
  };

  const creationTypeOnChange = (e, value) => {
    setCreationType(value);
  };

  const UserDetailsComponent = useMemo(() =>
    // preview component
    userDetailsComponents[creationType] || userDetailsComponents.single
  , [creationType]);

  const onHideModal = () => {
    setHideModal(true);
  };

  const onShowModal = () => {
    setHideModal(false);
  };

  return (
    <Modal
      open={openState}
      onClose={handleClose}
      style={{opacity: hideModal ? 0 : 1}}
    >
      <ModalContainer
        className={styles.modalContent}
        flexDirection="column"
      >
        <ModalHeader onClose={handleClose} title="Add User"/>
        <CreationType
          value={creationType}
          onChange={creationTypeOnChange}
          options={[{value: 'single', label: 'Single Account'}, {value: 'bulk', label: 'Bulk Account'}]}
        />
        <Box className={styles.details}>
          <SectionHeader title="User Details"/>
          <UserDetailsComponent
            onUsers={
              (users, openState = false) => {
                if (setUsers.current) {
                  setOpenState(openState);
                  setUsers.current(users);
                }
              }
            }
            onHideModal={onHideModal}
            onShowModal={onShowModal}
          />
        </Box>
      </ModalContainer>
    </Modal>
  );
});
